export const SkeletonCardCourseStudent = () => {
    return (
        <div className="w-full bg-white border border-gray-200 rounded-md shadow-sm">
            <div className="w-full grid grid-cols-1 gap-4 px-4 pt-4">
                <div className="w-full h-16 bg-gray-200 rounded-md animate-blink"></div>
            </div>
            <div className="w-full grid grid-cols-2 gap-4 p-4">
                <div className="w-full grid grid-cols-2 gap-4">
                    <div className="w-full h-60 bg-gray-200 rounded-md animate-blink"></div>
                    <div className="w-full h-60 bg-gray-200 rounded-md animate-blink"></div>

                </div>
                <div className="w-full h-60 bg-gray-200 rounded-md animate-blink"></div>
            </div>
        </div>
    )
}
