import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { DataGrid, esES } from '@mui/x-data-grid';
import { Heading } from "../../components/Heading";
import { Wrapper } from "../../components/Wrapper";
import { ContainerFull } from "../../components/ContainerFull";
import {
    UserIcon,
    TrashIcon,
    ArrowPathRoundedSquareIcon,
    MagnifyingGlassIcon
} from '@heroicons/react/24/solid';
import { toast } from 'react-hot-toast';

import {
    deleteStudentFromCourse,
    getCourseById,
    getCourseByIdentifier,
    getListStudentsByIdCourse,
    getListStudentsNotInCourse,
    requestDeleteStudentFromCourse
} from "../../redux/actions/course";
import { formatDate, validateSubscription } from "../../common/formatDateText";
import { exportToExcel } from "../../common/importDataToExcel";
import { ModalAddStudentToCourse } from "./components/ModalAddStudentToCourse";
import { ModalDeleteStudentList } from "./components/ModalDeleteStudentList";
import { optionsAllCourseIdentifier } from "../../redux/actions/options";
import { ComboBox } from "../../components/comboBox/ComboBox";
import { verifyStateApp } from "../../redux/actions/stateApp";
import { DocumentArrowDownIcon, UserPlusIcon } from "@heroicons/react/20/solid";
import { Loading } from "../../components/loading/Loading";
import { Tooltip, Zoom } from "@mui/material";


export const CourseStatus2 = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const identifier = params?.identifier || '';
    const idCourse = params?.idCourse || '';
    const [openAddStudentToCourseModal, setOpenAddStudentToCourseModal] = useState(false);
    const [openModalDeleteStudentList, setModalOpenDeleteStudentList] = useState(false);
    const [selectStudentDelete, setSelectStudentDelete] = useState(null);
    const [action, setAction] = useState('');
    const [loadingDeleteStudent, setLoadingDeleteStudent] = useState(false);
    const [identifierTitle, setIdentifierTitle] = useState('');

    const [findCourseIdentifier, setFindCourseIdenfier] = useState('');
    const [formData, setFormData] = useState({
        identifier: '',
    });

    useEffect(() => {
        return () => {
            setFormData({
                identifier: {
                    id: identifier,
                    description: identifier,
                    name: identifier
                }
            })
        }
    }, [identifier])

    const cancelDeleteStudentListRef = useRef(null);

    const user = useSelector((state) => state.user);
    const { user: { roles, typeUser, _id: userId } } = user;

    const { identifiers } = useSelector((state) => state.options);
    const { courseListSelected, courseSelected, loading } = useSelector((state) => state.course);

    useEffect(() => {
        setIdentifierTitle(courseSelected.identifier || '');
    }, [courseSelected]);

    const rows = courseListSelected;

    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        if (idCourse) {
            dispatch(getCourseById(idCourse));
            dispatch(getListStudentsByIdCourse(idCourse));
        }
        dispatch(getListStudentsNotInCourse(idCourse));
    }, [idCourse, dispatch]);

    useEffect(() => {
        dispatch(optionsAllCourseIdentifier());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getCourseById(idCourse));
    }, [identifier]);

    useEffect(() => {
        if (user) {
            setAction(typeUser === 'director' ? 'execute' : 'request')
        }
    }, [user]);

    useEffect(() => {
        if (openModalDeleteStudentList === false) {
            setSelectStudentDelete(null)
        }
    }, [openModalDeleteStudentList]);

    const handleSearchChange = (event) => {
        const searchText = event.target.value;
        setSearchText(searchText);
    };

    const handleDeleteStudentList = (student) => {
        setSelectStudentDelete(student);
        setModalOpenDeleteStudentList(true);
    }

    const handleConfirmDeleteStudentList = () => {
        setLoadingDeleteStudent(true);
        if (action === 'request') {
            dispatch(requestDeleteStudentFromCourse(courseSelected._id, selectStudentDelete.id, userId))
                .then((result) => {
                    if (result.status === 200) {
                        toast.success(result.message);
                        setModalOpenDeleteStudentList(false);
                        setSelectStudentDelete(null);
                        window.location.reload();
                    } else {
                        toast.error(result.message);
                    }
                    setLoadingDeleteStudent(false);
                });
        }

        if (action === 'execute') {
            dispatch(deleteStudentFromCourse(courseSelected._id, selectStudentDelete.id))
                .then((result) => {
                    if (result.status === 200) {
                        toast.success(result.message);
                        setModalOpenDeleteStudentList(false);
                        setSelectStudentDelete(null);
                        window.location.reload();
                    } else {
                        toast.error(result.message);
                    }
                    setLoadingDeleteStudent(false);
                });
        }
    }

    const validateForm = () => {
        if (!formData?.identifier) {
            toast.error('Ingresa un identificador');
            return false;
        }
        return true;
    }

    const handleSearchingCourse = (e) => {
        e.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            dispatch(getCourseByIdentifier(formData.identifier.id));
        }
    }

    const filteredCourseIdentifier = findCourseIdentifier === ''
        ? identifiers
        : identifiers.filter((identifier) =>
            identifier.description
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(findCourseIdentifier.toLowerCase().replace(/\s+/g, ''))
        );

    const columns = [
        { field: 'matricula', headerName: 'Matricula', flex: 1 },
        {
            field: 'firstName',
            headerName: 'Nombre',
            flex: 1,
            renderCell: (params) => (
                <p className='capitalize'>{params.value}</p>
            ),
        },
        {
            field: 'lastName',
            headerName: 'Apellido',
            flex: 1,
            renderCell: (params) => (
                <p className='capitalize'>{params.value}</p>
            ),
        },
        { field: 'email', headerName: 'Correo Electronico', flex: 1 },
        { field: 'phone', headerName: 'Telefono', flex: 1 },
        {
            field: 'paymentDeadlineDate',
            headerName: 'Fecha de vencimiento',
            flex: 1,
            renderCell: (params) => (
                <p className={`capitalize ${validateSubscription(params.value) ? 'text-green-600' : 'text-red-500'}`}>{formatDate(params.value)}</p>
            ),
        },
        {
            field: 'action',
            headerName: '',
            width: 200,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <div className='w-full flex justify-end gap-4'>
                    {typeUser === 'control escolar' && (
                        <Tooltip title="Solicitar eliminar a este estudiante del grupo" TransitionComponent={Zoom} placement='top'>
                            <button
                                onClick={() => handleDeleteStudentList({ id: params.row.id, name: `${params.row.firstName} ${params.row.lastName}` })}
                                className="flex justify-center shadow-sm shadow-red-600 bg-red-600 px-4 py-2 text-slate-50 rounded hover:bg-red-800"
                            >
                                <TrashIcon className="block h-5 w-5" aria-hidden="true" />
                            </button>
                        </Tooltip>
                    )}
                    {typeUser === 'director' && (
                        <Tooltip title="Eliminar a estudiante del grupo" TransitionComponent={Zoom} placement='top'>
                            <button
                                onClick={() => handleDeleteStudentList({ id: params.row.id, name: `${params.row.firstName} ${params.row.lastName}` })}
                                className="flex justify-center shadow-sm shadow-red-600 px-4 py-2 bg-red-600 mr-2 text-slate-50 rounded hover:bg-red-800"
                            >
                                <TrashIcon className="block h-5 w-5" aria-hidden="true" />
                            </button>
                        </Tooltip>
                    )}
                    <Tooltip title="Ver perfil del usuario" TransitionComponent={Zoom} placement='top'>
                        <Link
                            to={`/perfil/${params.row.id}`}
                            className="flex justify-center shadow-sm shadow-indigo-600 px-4 py-2 bg-indigo-600 mr-2 text-slate-50 rounded hover:bg-indigo-800"
                        >
                            <UserIcon className="block h-5 w-5" aria-hidden="true" />
                        </Link>
                    </Tooltip>
                </div>
            ),
        },
    ];

    const filteredRows = rows?.filter(
        (row) =>
            row.matricula?.toString().toLowerCase().includes(searchText.toString().toLowerCase()) ||
            row.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
            row.lastName?.toLowerCase().includes(searchText.toLowerCase()) ||
            row.email?.toLowerCase().includes(searchText.toLowerCase()) ||
            row.phone?.toLowerCase().includes(searchText.toLowerCase()) ||
            formatDate(row.paymentDeadlineDate)?.toLowerCase().includes(searchText.toLowerCase())
    );

    const formatDataExcel = async (data) => {
        const formattedData = data.map(item => ({
            matricula: item.matricula,
            nombre: item.firstName,
            segundo_nombre: item.secondName,
            primer_apellido: item.lastName,
            segundo_apellido: item.secondSurname,
            correo_electronico: item.email,
            telefono: item.phone,
            fecha_vencimiento: formatDate(item.paymentDeadlineDate)
        }));
        return formattedData;
    }

    const handleImportData = async (data, name) => {
        dispatch(verifyStateApp())
            .then((response) => {
                if (response.data === false) {
                    toast.error(response.message)

                } else {
                    confirDownloadFile(data, name);
                }
            });
    };

    const confirDownloadFile = async (data, name) => {
        const dataReady = await formatDataExcel(data);
        const response = exportToExcel(dataReady, name);
        response.data ? toast.error(response.message) : toast.success(response.message);
    }


    return (
        <ContainerFull>
            <Heading
                title={'Lista de estudiantes del curso - '}
                subtitle={`Muestra la lista de todo el personal registrados en el plataforma.`}
                center={false}
                identifierCourse={identifierTitle}
            />
            <div className='w-full flex flex-col md:flex-row lg:justify-end mt-4 lg:mt-0'>
                <form
                    onSubmit={(e) => handleSearchingCourse(e)}
                    className='flex flex-col w-full lg:w-[50%] lg:flex-row items-center lg:items-end gap-4 mt-4'
                >
                    <div className='flex w-full lg:w-[70%]'>
                        <div className='w-full mr-2'>
                            <ComboBox
                                filterData={filteredCourseIdentifier}
                                query={findCourseIdentifier}
                                setQuery={setFindCourseIdenfier}
                                selected={formData}
                                setSelected={setFormData}
                                placeholder='Seleciona tu curso'
                                property='identifier'
                            />
                        </div>
                        <Tooltip title="Buscar por identificador de curso" TransitionComponent={Zoom} placement='top'>
                            <button
                                type='submit'
                                className='rounded-md px-4 py-2 font-semibold text-md text-white bg-indigo-600 shadow-sm shadow-indigo-600 hover:bg-indigo-800'
                            ><MagnifyingGlassIcon className="block h-5 w-5" aria-hidden="true" /></button>
                        </Tooltip>
                    </div>
                </form>
                <div className="flex w-full justify-end items-center lg:w-[50%] gap-4 mt-4">
                    {courseListSelected && courseListSelected.length > 0 && (
                        <Tooltip title="Descargar archivo de Excel" TransitionComponent={Zoom} placement='top'>
                            <button
                                type='button'
                                onClick={() => handleImportData(courseListSelected, 'Lista_de_estudiantes.xlsx')}
                                className='rounded-md px-4 py-2 font-semibold text-md text-white bg-indigo-600 shadow-sm shadow-indigo-600 hover:bg-indigo-800'
                            ><DocumentArrowDownIcon className="block h-5 w-5" aria-hidden="true" /></button>
                        </Tooltip>
                    )}
                    {roles === 'admin' &&
                        action === 'request' ? (
                        <Tooltip title="Solicitar eliminar a estudiante del curso" TransitionComponent={Zoom} placement='top'>
                            <button
                                type='button'
                                onClick={() => setOpenAddStudentToCourseModal(true)}
                                className='rounded-md px-4 py-2 font-semibold text-md text-white bg-sky-600 shadow-sm shadow-sky-600 hover:bg-sky-800'
                            ><ArrowPathRoundedSquareIcon className="block h-5 w-5" aria-hidden="true" /></button>
                        </Tooltip>
                    ) : (
                        <Tooltip
                            title={courseSelected?.limitMembers > 0 ? "Agregar un nuevo estudiante al grupo" : "Ya no puedes agregar más estudiantes al grupo"}
                            TransitionComponent={Zoom}
                            placement='top'
                        >
                            <button
                                type='button'
                                disabled={!(courseSelected?.limitMembers > 0)}
                                onClick={() => setOpenAddStudentToCourseModal(true)}
                                className='rounded-md px-4 py-2 font-semibold text-md text-white bg-indigo-600 shadow-sm shadow-indigo-600 hover:bg-indigo-800 disabled:opacity-50'
                            ><UserPlusIcon className="block h-5 w-5" aria-hidden="true" /></button>
                        </Tooltip>
                    )
                    }
                </div>
            </div>
            {courseListSelected && (
                <>
                    {
                        rows.length > 0 && (
                            <Wrapper>
                                <input
                                    type="text"
                                    placeholder="Buscar a un usuario"
                                    value={searchText}
                                    onChange={handleSearchChange}
                                    className='block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' />
                            </Wrapper>
                        )
                    }
                    <Wrapper>
                        <div className="w-full overflow-x-auto">
                            {
                                loading
                                    ? (
                                        <Loading />
                                    ) : (
                                        <>
                                            {
                                                rows.length > 0
                                                    ? (
                                                        <DataGrid
                                                            rows={filteredRows}
                                                            columns={columns}
                                                            // onRowClick={handleRowClick}
                                                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                                            initialState={{
                                                                pagination: {
                                                                    paginationModel: { pageSize: 10, page: 0, },
                                                                },
                                                            }}
                                                            pageSizeOptions={[10, 15, 25]}
                                                        />
                                                    ) : (
                                                        <p className='text-gray-400'>Este grupo no tiene estudiantes</p>
                                                    )
                                            }
                                        </>
                                    )
                            }
                        </div>
                    </Wrapper>
                </>
            )}
            <ModalDeleteStudentList
                open={openModalDeleteStudentList}
                setOpen={setModalOpenDeleteStudentList}
                cancelButtonRef={cancelDeleteStudentListRef}
                confirmAction={handleConfirmDeleteStudentList}
                loadingDeleteStudent={loadingDeleteStudent}
                title={
                    action === 'request'
                        ? 'Solicitud para eliminar a estudiante del curso'
                        : 'Eliminar estudiante del curso'}
                student={selectStudentDelete}
                labelButonConfirm={action === 'request'
                    ? 'Confirmar solicitud'
                    : 'Eliminar alumno'
                }
                message={action === 'request'
                    ? '¿Estás seguro de solicitar la baja del estudiante '
                    : '¿Estás seguro de eliminar al estudiante '
                }
            />
            <ModalAddStudentToCourse
                open={openAddStudentToCourseModal}
                setOpen={setOpenAddStudentToCourseModal}
                idCourse={courseSelected._id}
            />
        </ContainerFull>
    )
}
