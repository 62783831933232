import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-hot-toast';

import { MultiSelect } from "react-multi-select-component";

import { InputText } from '../../components/inputs/InputText';
import { Button } from '../../components/buttons/Button';
import { ButtonLoader } from '../../components/buttons/ButtonLoader';
import { ComboBox } from '../../components/comboBox/ComboBox';

import { resetEmailVerification } from '../../redux/reducers/preRegistration';
import { registerPreRegitration } from '../../redux/actions/preRegistration';
import { optionsLanguagesMulti } from '../../redux/actions/options';

import { levelEducation, locationState } from '../../static/data';
import logo from '../../static/image/logo.png';
import { InputPhone } from '../../components/inputPhone/InputPhone';
import { InputDateWithIcon } from '../../components/inputDateWithIcon/InputDateWithIcon';
import { isValidPhoneNumber } from '../../common/phoneInputValid';
import image from '../../static/image/10.png';

export const FormValidacionDatos = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { languages } = useSelector((state) => state.options);
	const [findLevelEducation, setFindLevelEducation] = useState('');
	const [findLocation, setFindLocation] = useState('');
	const [selectDate, setSelectDate] = useState(null);
	const [selectedLanguage, setSelectedLanguage] = useState([]);

	const {
		email,
		success,
		loading
	} = useSelector((state) => state.preRegistration);

	const [loadingForm, setLoadingForm] = useState(false);

	useEffect(() => {
		dispatch(optionsLanguagesMulti());
	}, [dispatch]);

	useEffect(() => {
		if (!email) {
			navigate(`/pre-registro`)
		}
	})

	useEffect(() => {
		if (success) {
			setStep(3)
		}
	}, [success])

	const goBackStart = () => {
		dispatch(resetEmailVerification())
		navigate(`/pre-registro`)
	};

	const [step, setStep] = useState(1);
	const totalSteps = 3;

	const [formData, setFormData] = useState({
		firstName: '',
		secondName: '',
		lastName: '',
		secondSurname: '',
		email: email,
		phone: '',
		dateBirth: '',
		location: '',
		language: '',
		education: '',
		usageRights: false,
		rfc: '',
	});

	useEffect(() => {
		setFormData((prevData) => ({
			...prevData,
			dateBirth: selectDate,
		}));
	}, [selectDate]);

	const handleContinue = () => {
		dispatch(resetEmailVerification())
		navigate(`/`);
	}

	const handleNext = () => {
		if (step < totalSteps) {
			setStep(step + 1);
		}
	};

	const handlePrev = () => {
		if (step > 1) {
			setStep(step - 1);
		}
	};

	const onChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	}

	const handleSelectLanguage = () => {
		// Verificar si el arreglo es válido
		if (!Array.isArray(selectedLanguage)) {
			return "Entrada no válida. Se espera un arreglo.";
		}
		// Extraer las etiquetas y unirlas en un solo string separado por comas
		const etiquetas = selectedLanguage.map(objeto => objeto.label).join(' ');

		return etiquetas;
	}

	const handleFinish = () => {
		const updateState = { ...formData };
		updateState.education = formData.education.description;
		updateState.language = handleSelectLanguage();
		updateState.location = formData.location.description;
		const valid = validarCampos(updateState);
		if (valid) {
			setLoadingForm(true);
			dispatch(registerPreRegitration(updateState))
				.then((result) => {
					if (result?.status === 201) {
						toast.success(result.message);
					} else {
						toast.error(result.message);
					}
					setLoadingForm(false);
				});
		} else {
			setLoadingForm(false);
		}
	};

	const validarCampos = (updateState) => {
		if (updateState.firstName.trim() === '') {
			toast.error('Ingresa tu nombre');
			return false;
		}
		if (updateState.lastName.trim() === '') {
			toast.error('Ingresa tu apellido paterno');
			return false;
		}
		if (updateState.secondSurname.trim() === '') {
			toast.error('Ingresa tu apellido materno');
			return false;
		}
		if (updateState.phone.trim() === '') {
			toast.error('Ingresa tu telefono');
			return false;
		}
		if (updateState.phone) {
			const validPhone = isValidPhoneNumber(updateState.phone);
			if (!validPhone) {
				toast.error('El número de teléfono debe contener exactamente 10 dígitos.');
				return false;
			}
		}
		if (updateState.phone.length > 14) {
			toast.error('Ingresa un telefono valido con 10 digitos.');
			return false;
		}
		if (updateState.dateBirth === null) {
			toast.error('Ingresa tu fecha de nacimiento');
			return false;
		}
		if (updateState.location === undefined) {
			toast.error('Ingresa tu ubicacion');
			return false;
		}
		if (updateState.education === undefined) {
			toast.error('Ingresa tu nivel educativo');
			return false;
		}
		if (updateState.language === '') {
			toast.error('Selecciona tus idiomas de interes');
			return false;
		}
		if (!updateState.usageRights) {
			toast.error('Debes aceptar los terminos y condiciones, para continuar con el proceso de registro');
			return false;
		};
		return true;
	};

	const filteredLocations = findLocation === ''
		? locationState
		: locationState.filter((location) =>
			location.description
				.toLowerCase()
				.replace(/\s+/g, '')
				.includes(findLocation.toLowerCase().replace(/\s+/g, ''))
		);

	const filteredLevelEducations = findLevelEducation === ''
		? levelEducation
		: levelEducation.filter((levelEducation) =>
			levelEducation.description
				.toLowerCase()
				.replace(/\s+/g, '')
				.includes(findLevelEducation.toLowerCase().replace(/\s+/g, ''))
		);

	const renderStep = () => {
		switch (step) {
			case 1:
				return (
					<div
						className='w-full flex flex-col justify-between py-10 overflow-auto bg-white'
						style={{ height: "100dvh" }}
					>
						{/* Header form */}
						<div>
							<div className='flex justify-center items-center'>
								<div className='w-[45%] md:w-[40%]'>
									<img src={logo} alt="logo" />
								</div>
							</div>
							<div className='flex justify-center items-center mt-2'>
								<div className='w-10/12 mx-auto'>
									<h1 className='text-2xl font-bold text-gray-950  text-center'>
										Bienvenido a CFA
									</h1>
								</div>
							</div>
							<div className='flex justify-center items-center mt-4'>
								<div className='w-[85%] md:w-2/3'>
									<h2 className='text-sm md:text-md font-normal text-gray-600 text-center break-words'>
										Para continuar con el proceso de registro ingresa tu correo electronico y sigue las instrucciones del correo electronico.
									</h2>
								</div>
							</div>
						</div>
						{/* Body form */}
						<div className='flex items-center mt-8'>
							<div className='w-[90%] md:w-10/12 mx-auto grid grid-cols-1 md:grid-cols-2 gap-8'>
								<InputText
									id={'firstName'}
									name={'firstName'}
									type={'text'}
									label={'Nombre'}
									onChange={(e) => onChange(e)}
									value={formData.firstName}
									placeholder={'Ingresa tu nombre'}
									disabled={false}
								/>
								<InputText
									id={'secondName'}
									name={'secondName'}
									type={'text'}
									label={'Otros nombres'}
									onChange={(e) => onChange(e)}
									value={formData.secondName}
									placeholder={'Ingresa tus otros nombres'}
									disabled={false}
								/>
								<InputText
									id={'lastName'}
									name={'lastName'}
									type={'lastName'}
									label={'Apellido Paterno'}
									onChange={(e) => onChange(e)}
									value={formData.lastName}
									placeholder={'Ingresa tu apellido paterno'}
									disabled={false}
								/>
								<InputText
									id={'secondSurname'}
									name={'secondSurname'}
									type={'text'}
									label={'Apellido Materno'}
									onChange={(e) => onChange(e)}
									value={formData.secondSurname}
									placeholder={'Ingresa tu apellido materno'}
									disabled={false}
								/>
								<InputPhone
									id={'phone'}
									name={'phone'}
									type={'phone'}
									label={'Telefono'}
									onChange={(e) => onChange(e)}
									value={formData.phone}
									placeholder={'Ingresa tu telefono'}
									disabled={false}
								/>
								<InputDateWithIcon
									id={'dateBirth'}
									label={'Fehca de nacimiento'}
									onChange={setSelectDate}
									value={selectDate}
									placeholder={'Ingresa tu fecha de nacimiento'}
								/>
								<div>
									<h3 className="text-md font-semibold text-gray-900">Idiomas de interes</h3>
									<MultiSelect
										className='capitalize text-sm mt-2'
										options={languages}
										value={selectedLanguage}
										onChange={setSelectedLanguage}
										overrideStrings={{
											selectSomeItems: "Seleciona tus idiomas de interes",
											selectAll: "Seleciona todos",
											allItemsAreSelected: "Todos los elementos están seleccionados",
											clearSearch: "Limpiar búsqueda",
											search: "Buscar",
											noOptions: "Sin opciones"
										}}
									/>
								</div>
								<div>
									<h3 className="text-md font-semibold text-gray-900">Lugar de residencia</h3>
									<ComboBox
										filterData={filteredLocations}
										query={findLocation}
										setQuery={setFindLocation}
										selected={formData}
										setSelected={setFormData}
										placeholder='Selecione el estado donde vives'
										property='location'
									/>
								</div>
								<div>
									<h3 className="text-md font-semibold text-gray-900">Nivel educativo</h3>
									<ComboBox
										filterData={filteredLevelEducations}
										query={findLevelEducation}
										setQuery={setFindLevelEducation}
										selected={formData}
										setSelected={setFormData}
										placeholder='Seleciona tu nivel educativo'
										property='education'
									/>
								</div>
								<InputText
									id={'rfc'}
									name={'rfc'}
									type={'text'}
									label={'RFC'}
									onChange={(e) => onChange(e)}
									value={formData.rfc}
									placeholder={'Ingresa tu rfc'}
									disabled={false}
								/>
							</div>
						</div>
						<div className="flex w-[90%] md:w-10/12 mx-auto justify-between mt-8">
							<Button
								label='Cancelar'
								disabled={loading}
								onClick={() => goBackStart()}
							/>
							<Button
								label='Siguiente'
								onClick={handleNext}
							/>
						</div>

					</div>
				);
			case 2:
				return (
					<div
						className='w-full flex flex-col justify-between py-10 overflow-auto bg-white'
						style={{ height: "100dvh" }}
					>
						{/* Header form */}
						<div>
							<div className='flex justify-center items-center'>
								<div className='w-[45%] md:w-[40%]'>
									<img src={logo} alt="logo" />
								</div>
							</div>
							<div className='flex justify-center items-center mt-2'>
								<div className='w-10/12 mx-auto'>
									<h1 className='text-2xl font-bold text-gray-950  text-center'>
										Terminos y condiciones
									</h1>
								</div>
							</div>
							<div className='flex justify-center items-center mt-4'>
								<div className='w-[85%] md:w-2/3'>
									<h2 className='text-sm md:text-md font-normal text-gray-600 text-center break-words'>
										Lee y acepta los terminos y condiciones para continuar con el proceso de registro.
									</h2>
								</div>
							</div>
						</div>
						{/* Body form */}
						<div className='flex items-center mt-4'>
							<div className='w-[90%] md:w-10/12 mx-auto grid grid-cols-1 gap-y-4'>
								<div className='w-full'>
									<div className='w-full h-[500px] overflow-auto text-sm break-words p-4 border border-gray-200 bg-indigo-50 rounded-md text-justify'>
										<p className='font-semibold text-justify break-words'>CONTRATO DE PRESTACIÓN DE SERVICIOS EDUCATIVOS EN SU MODALIDAD DE ESCUELA DE IDIOMAS EXTRANJEROS, QUE CELEBRAN POR UNA PARTE SERVICIOS ESCOLARES CFA, S.A.S., REPRESENTADA EN ESTE ACTO POR LA SRTA. SOFIA LETICIA AGUILAR ARGUELLES, EN ADELANTE “CFA”, Y POR LA OTRA PARTE, POR SU PROPIO DERECHO <span className='bg-gray-300 text-[16px] px-1 capitalize'>{formData.firstName ? `${formData?.firstName} ${formData?.secondName} ${formData?.lastName} ${formData?.secondSurname}` : "_____________________"}</span>, EN ADELANTE “EL CONTRATANTE”, Y CUANDO COMPAREZCAN EN FORMA CONJUNTA SERÁN LLAMADOS “LAS PARTES”, AL TENOR DE LAS SIGUIENTES DECLARACIONES Y CLÁUSULAS:</p>
										<p className='text-center my-4 font-medium'>DECLARACIONES</p>
										<p>I. Declara CFA por conducto de su apoderado legal:</p>
										<div class="p-6">
											<ol class="list-lowercase list-[lower-alpha] space-y-4 pl-8 text-gray-800">
												<li>
													Ser una sociedad mexicana legalmente constituida, según lo acredita la escritura pública Instrumento bajo folio de constitución SAS2021401156 con fecha del 13 de Mayo del 2021, inscrito en el registro público de comercio con Folio Mercantil N-2021032309, donde de igual manera se acreditan las facultades de su representante legal para comparecer a la firma del presente instrumento.
												</li>
												<li>
													Ser su objeto social, en forma enunciativa y no limitativa la firma de contratos como el que ocupa.
												</li>
												<li>
													Ser su R.F.C.: SEC210513RD1.
												</li>
												<li>
													Ser su domicilio el ubicado en Calle Socorro Blanc Ruiz, Numero 245, colonia Villa jardín, C.P. 78396 en el municipio de San Luis Potosí, S.L.P.
												</li>
												<li>
													Que este ya indicó a EL CONTRATANTE el costo por los servicios de educación en su modalidad de idiomas extranjeros (en adelante denominado como EL SERVICIO) así como las restricciones estipuladas en el presente Contrato.
												</li>
												<li>
													Declara ser el actual dueño y administrador de la página de internet <a href="https://www.cfamex.com/index.html" class="text-blue-600 underline">https://www.cfamex.com/index.html</a>, en el cual prevé los servicios de educación de lenguas extranjeras vía remota, servicios los cuales se ofrecen objeto del presente contrato.
												</li>
												<li>
													Que cuenta con la capacidad económica y jurídica para comparecer y obligarse en los términos de esta relación contractual.
												</li>
												<li>
													Ser su voluntad comparecer a la firma de este contrato, y obligarse en los términos adelante señalados.
												</li>
											</ol>
										</div>
										<p>II. Declara EL CONTRATANTE por conducto de su apoderada legal:</p>
										<div class="p-6">
											<ol class="list-lowercase list-[lower-alpha] space-y-4 pl-8 text-gray-800">
												<li>
													Es una persona física mayor de edad, de nombre, y capacidad jurídica para obligarse en los términos del presente Contrato.
												</li>
												<li>
													Su domicilio se encuentra ubicado en la ciudad de <span className='bg-gray-300 text-[16px] px-1 capitalize font-semibold'>{formData.location ? `${formData?.location.description}` : "_____________________"}</span>, el cual señala como domicilio convencional para todos los efectos legales del presente Contrato.
												</li>
												<li>
													Recibió del CFA toda la información relativa al Servicio Educativo objeto del presente Contrato, de costos, modalidades e idiomas, así como el reglamento interno del Plantel.
												</li>
												<li>
													Que cuenta con la capacidad jurídica para comparecer y obligarse en los términos de esta relación contractual.
												</li>
												<li>
													Ser su voluntad comparecer a la firma de este contrato, y obligarse en los términos adelante señalados.
												</li>
											</ol>
										</div>
										<p>III. Declaran LAS PARTES:</p>
										<div class="p-6">
											<ol class="list-lowercase list-[lower-alpha] space-y-4 pl-8 text-gray-800">
												<li>
													LAS PARTES expresan que en este contrato no existe error, dolo, lesión, o cualquier otro vicio que pudiera originar la nulidad del mismo.
												</li>
												<li>
													Ser su voluntad ajustar esta relación contractual a lo establecido en las siguientes:
												</li>
											</ol>
										</div>
										<p className='text-center my-4 font-medium'>GLOSARIO</p>
										<div class="p-6">
											<ol class="list-lowercase list-[lower-alpha] space-y-4 pl-8 text-gray-800">
												<li>
													<span class="font-semibold">Alumno:</span> La persona física que recibe del proveedor el servicio educativo en su modalidad de idioma extranjero a cambio de un precio cierto y determinado.
												</li>
												<li>
													<span class="font-semibold">Anexo:</span> Es la descripción detallada de lo que contempla el servicio educativo, así como la mensualidad que deberá de cubrir el Contratante como resultado del costo total correspondiente a lo estipulado en la Cláusula Segunda del presente Contrato.
												</li>
												<li>
													<span class="font-semibold">Contratante:</span> Persona física que paga un precio cierto y determinado por el servicio educativo otorgado por CFA.
												</li>
												<li>
													<span class="font-semibold">Modalidad Remota:</span> Tipo de servicio educativo que se impartirá, describiendo que las clases impartidas del idioma extranjero seleccionado por EL CONTRATANTE, se realizarán de manera NO PRESENCIAL, es decir, por mecanismos electrónicos de videollamada, transmisión en vivo “streaming”, por los medios y herramientas que las partes convengan.
												</li>
												<li>
													<span class="font-semibold">CFA:</span> La persona moral que ofrece el servicio educativo en su modalidad remota, mediante el cobro de un precio cierto y determinado.
												</li>
												<li>
													<span class="font-semibold">Servicio Educativo:</span> Es el servicio de enseñanza que, conforme a los planes de estudio el proveedor se obliga a prestar al alumno, dependiendo del idioma que este seleccione.
												</li>
												<li>
													<span class="font-semibold">Portal Digital:</span> Se referiría a la página de internet, <a href="https://www.cfamex.com/index.html" class="text-blue-600 underline">https://www.cfamex.com/index.html</a>, la cual es operada por la moral CFA, en donde el alumno podrá crear una cuenta única y personal, protegida con un usuario y contraseña de su única elección y responsabilidad de resguardo, en donde podrá elegir de manera libre acorde a las limitaciones de la plataforma, los cursos que deseará inscribirse, así como los horarios que le sean funcionales de acuerdo a sus necesidades para la toma de clases remotas.
												</li>
											</ol>
										</div>
										<p className='text-center my-4 font-medium'>DECLARACIONES</p>
										<div class="p-6 space-y-8">
											<div>
												<h2 class="text-[14px] font-semibold mb-2">PRIMERA: Objeto y consentimiento de las partes del contrato</h2>
												<p class="text-gray-800">
													Para efectos de este contrato, CFA se obliga a prestar el Servicio Educativo de idioma extranjero, cuya descripción, modalidad y especificaciones se encuentran en el Anexo del presente, y EL CONTRATANTE, en consecuencia, a pagar un precio cierto y determinado.
												</p>
											</div>

											<div>
												<h2 class="text-[14px] font-semibold mb-2">SEGUNDA: Contraprestación, forma y lugar de pago</h2>
												<p class="text-gray-800">
													Como contraprestación por el Servicio Educativo objeto del presente Contrato, EL CONTRATANTE pagará a CFA la cantidad total en conformidad a los cursos contratados dentro del portal digital, dicha cantidad será pagadera de manera mensual, y se realizará por medio de transferencia bancaria a nombre de CFA.
												</p>
											</div>

											<div>
												<h2 class="text-[14px] font-semibold mb-2">TERCERA: Terminación anticipada y devolución de pagos</h2>
												<p class="text-gray-800">
													Son causas de terminación anticipada del presente Contrato, que EL CONTRATANTE antes de que se haya iniciado la prestación del Servicio Educativo avise al Proveedor que no requiere del Servicio Educativo, por lo que CFA deberá devolver los montos pagados por inscripción o reinscripción cuando:
												</p>
												<ul class="list-[lower-alpha] list-inside ml-4 text-gray-800">
													<li>EL CONTRATANTE lo haga de conocimiento por escrito cuando menos 5 días (cinco) antes del inicio de las clases de idioma extranjero contratado.</li>
													<li>CFA pierda los permisos, reconocimientos e incorporaciones otorgadas por las autoridades competentes.</li>
												</ul>
											</div>

											<div>
												<h2 class="text-[14px] font-semibold mb-2">CUARTA: Obligaciones de CFA</h2>
												<p class="text-gray-800">Serán obligaciones comprendidas en este contrato por parte de CFA contra EL CONTRATANTE las siguientes:</p>
												<ul class="list-[lower-alpha] list-inside ml-4 text-gray-800 space-y-2">
													<li>Presentar a EL CONTRATANTE, los ajustes a los diferentes conceptos de cobro para próximos cursos de idiomas extranjeros, cuando menos 60 (sesenta) días antes del periodo de reinscripción.</li>
													<li>No incrementar las cuotas de pago pactadas, durante el curso que se esté tomando.</li>
													<li>No establecer cuotas o aportaciones extraordinarias a EL CONTRATANTE. En caso de que sea solicitado algún donativo en efectivo o especie, éste tendrá el carácter de estrictamente voluntario.</li>
													<li>No exigir la adquisición de útiles escolares, libros y otros artículos o servicios que puedan ser adquiridos en el comercio en general.</li>
													<li>No exigir la adquisición de útiles escolares, libros y otros artículos o servicios que puedan ser adquiridos en el comercio en general. En caso de que sea indispensable la adquisición de dichos artículos o servicios con determinados proveedores, los precios de los mismos no deberán ser superiores a los del comercio en general.</li>
													<li>Los gastos de celebración de eventos cívicos, sociales o recreativos organizados o promovidos por CFA, serán estrictamente voluntarios.</li>
													<li>Entregar las boletas de calificaciones, así como los certificados correspondientes al término de cada curso lectivo.</li>
												</ul>
											</div>

											<div>
												<h2 class="text-[14px] font-semibold mb-2">QUINTO: Obligaciones de EL CONTRATANTE</h2>
												<p class="text-gray-800">Serán obligaciones comprendidas en este contrato por parte de EL CONTRATANTE contra CFA las siguientes:</p>
												<ul class="list-[lower-alpha] list-inside ml-4 text-gray-800 space-y-2">
													<li>Entregar la documentación señalada en el presente Contrato para la inscripción del Alumno.</li>
													<li>Cumplir oportunamente los montos como contraprestación previstos en este Contrato.</li>
													<li>Colaborar con CFA en las actividades que éste realice en beneficio de la educación del Alumno.</li>
													<li>Participar conforme al Reglamento del Instituto, en el tratamiento de los problemas de conducta y aprendizaje del Alumno.</li>
													<li>Cumplir con las obligaciones establecidas en el Reglamento.</li>
												</ul>
											</div>

											<div>
												<h2 class="text-[14px] font-semibold mb-2">SEXTO: Causas de rescisión</h2>
												<p class="text-gray-800">Serán causas de rescisión del presente contrato las siguientes:</p>
												<p class="text-gray-800 font-semibold">Por parte de EL CONTRATANTE:</p>
												<ul class="list-[lower-alpha] list-inside ml-4 text-gray-800 space-y-2">
													<li>El incumplimiento a cualquiera de las obligaciones establecidas en el presente Contrato.</li>
													<li>El incumplimiento con la obligación de pago 3 (tres) fechas de pago continuas.</li>
												</ul>
												<p class="text-gray-800 font-semibold mt-4">Por parte de CFA:</p>
												<ul class="list-[lower-alpha] list-inside ml-4 text-gray-800 space-y-2">
													<li>El incumplimiento a cualquiera de las obligaciones establecidas en el presente Contrato.</li>
													<li>El cobro forzoso por conceptos adicionales que no correspondan a la prestación del Servicio Educativo necesario para que el Alumno pueda cumplir con los planes y programas de estudios.</li>
													<li>En caso de que les sea retirado la autorización y/o el reconocimiento de validez oficial correspondiente.</li>
													<li>Si CFA es declarado en quiebra por sentencia ejecutoriada.</li>
												</ul>
												<p className='my-4'>En caso de rescisión del presente Contrato por alguna de las causas estipuladas en la presente Cláusula, EL CONTRATANTE deberá de pagar el total de los pagos equivalentes al servicio que en el que recibió el Servicio Educativo y CFA deberá devolver, en un lapso no mayor a 15 (quince) días naturales a partir de dicha rescisión, las cantidades pagadas por adelantado que en su caso haya realizado EL CONTRATANTE, además de la Pena Convencional establecida en la Cláusula siguiente.</p>
											</div>

											<div>
												<h2 class="text-[14px] font-semibold mb-2">SEPTIMO: Pena convencional</h2>
												<p class="text-gray-800">
													En caso de incumplimiento por alguna de LAS PARTES a las obligaciones objeto del presente Contrato, la pena convencional será equivalente al 15 % (quince por Ciento) del precio total del Servicio Educativo.

													Esta sanción se estipula porque haya retraso en el cumplimiento de las obligaciones y que la prestación del Servicio Educativo sea deficiente por causas imputables CFA, sin perjuicio del derecho que tienen Las Partes de optar entre exigir el cumplimiento del Contrato o rescindirlo.

													LAS PARTES acuerdan que en caso de que la prestación del Servicio Educativo sea deficiente, EL CONTRATANTE deberá de hacerlo del conocimiento a CFA para que éste último pueda implementar las acciones de mejora correspondiente.

													En caso de que alguna de LAS PARTES requiera el pago de la Pena Convencional por cualquiera de los supuestos señalados en esta cláusula, deberán de solicitar por escrito el pago de dichas penas, debiendo hacer el pago en los 15 (quince) días naturales siguientes de haber recibido dicha solicitud.

												</p>
											</div>

											<div>
												<h2 class="text-[14px] font-semibold mb-2">OCTAVO: Procedimiento para quejas y reclamaciones</h2>
												<p class="text-gray-800">
													EL CONTRATANTE podrá interponer queja o reclamación acudiendo al domicilio de CFA, vía telefónica, correo electrónico o cualquier otro medio de contacto que CFA le comparta para estos fines. Los datos del domicilio y teléfono se encuentran en las Declaraciones de CFA del presente Contrato. CFA deberá de atender la queja o reclamación en un lapso no mayor de 48 (cuarenta y ocho) horas contadas a partir de que sea recibida la queja.
												</p>
											</div>

											<div>
												<h2 class="text-[14px] font-semibold mb-2">NOVENO: Cancelación del contrato</h2>
												<p class="text-gray-800">
													EL CONTRATANTE podrá cancelar el presente Contrato, sin responsabilidad y penalización alguna de su parte, dentro de los 5 (cinco) días hábiles posteriores a la firma del presente Contrato, en cuyo caso CFA se obliga a reintegrar todas las cantidades que le hubiere entregado EL CONTRATANTE en un plazo igual al indicado en la presente Cláusula. La cancelación deberá hacerse de manera escrita en el domicilio de CFA, o bien, por los mecanismos de comunicación que CFA le haya proporcionado para estos fines, tomando como fecha de revocación la de recepción para su envío.
													La cancelación aplica, siempre y cuando el servicio objeto del contrato no se haya empezado a prestar.

												</p>
											</div>

											<div>
												<h2 class="text-[14px] font-semibold mb-2">DECIMA: Contratación por medios electrónicos</h2>
												<p class="text-gray-800">
													LAS PARTES acuerdan que, en lugar de una firma original autógrafa, este contrato, así como cualquier consentimiento, aprobación u otros documentos relacionados con el mismo, podrán ser firmados por medio del uso de firmas electrónicas, digitales, numéricas, alfanuméricas, huellas de voz, biométricas o de cualquier otra forma y que dichos medios alternativos de firma y los registros en donde sean aplicadas dichas firmas, serán consideradas para todos los efectos, incluyendo pero no limitado a la legislación civil, mercantil, protección al consumidor y a la NOM-151-SCFI-2016, con la misma fuerza y consecuencias que la firma autógrafa original física de la parte firmante. Si el contrato o cualquier otro documento relacionado con el mismo es firmado por medios electrónicos o digitales, LAS PARTES acuerdan que los formatos del contrato y los demás documentos firmados de tal modo serán conservados y estarán a disposición de EL CONTRATANTE, por lo que convienen que cada una y toda la información enviada por CFA a la dirección de correo electrónico proporcionada por EL CONTRATANTE al momento de celebrar el presente Contrato será considerada como entregada en el momento en que la misma es enviada, siempre y cuando exista confirmación de recepción.

													Así mismo, se declara que el presente contrato se encuentra en conformidad a la normativa actual vigente en nuestro pais, incluyendo de manera enunciativa mas no limitativa la NMX-COE-001-SCFI-2018 y el Titulo Segundo del Código de Comercio.

												</p>
											</div>

											<div>
												<h2 class="text-[14px] font-semibold mb-2">DECIMA PRIMERA: Caso fortuito y fuerza mayor</h2>
												<p class="text-gray-800">
													LAS PARTES no serán responsables de cualquier atraso o incumplimiento del presente Contrato, cuando el mismo sea resultado de caso fortuito o fuerza mayor.
													Se entiende por caso fortuito o causa de fuerza mayor, aquellos hechos o acontecimientos ajenos a la voluntad de LAS PARTES, siempre y cuando, dichos hechos o acontecimientos sean
													imprevisibles, irresistibles, insuperables, actuales y no provengan de alguna negligencia o provocación de alguna de LAS PARTES. En caso de que alguna de Las Partes se encuentre imposibilitada para cumplir con el presente Contrato, deberá de hacerlo del conocimiento de la otra parte por escrito.

												</p>
											</div>

											<div>
												<h2 class="text-[14px] font-semibold mb-2">DECIMA SEGUNDA: Confidencialidad</h2>
												<p class="text-gray-800">
													LLAS PARTES convienen que el presente Contrato tiene el carácter de confidencial, por lo que CFA se obliga a mantener los datos DEL CONTRATANTE con tal carácter y únicamente podrá ser revelada la información contenida en el mismo por mandamiento de autoridad competente; de igual forma CFA se obliga a no ceder o transmitir a terceros con fines mercadotécnicos, publicitarios o de cualquier otra índole los datos e información proporcionada por EL CONTRATANTE con motivo del presente Contrato, ni enviar publicidad sobre los bienes y servicios, salvo que conste la autorización expresa DEL CONTRATANTE en el presente Contrato.
												</p>
											</div>

											<div>
												<h2 class="text-[14px] font-semibold mb-2">DECIMA TERCERA: Aviso de privacidad</h2>
												<p class="text-gray-800">
													Previo a la firma del presente Contrato, y en cumplimiento a lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, CFA hizo del conocimiento al CONTRATANTE del aviso de privacidad, así como del procedimiento para ejercer los derechos de acceso, rectificación, cancelación y oposición al tratamiento de sus datos personales en adelante, derechos ARCO.
												</p>
											</div>

											<div>
												<h2 class="text-[14px] font-semibold mb-2">DECIMA CUARTA: Competencia</h2>
												<p class="text-gray-800">
													Para todo lo relativo a la interpretación, aplicación y cumplimiento del presente Contrato, LAS PARTES acuerdan someterse en la vía administrativa a la Procuraduría Federal del Consumidor, y en caso de subsistir diferencias, a la jurisdicción de los tribunales competentes del lugar donde se celebra este Contrato.
													Por lo anterior, cualquier otro uso y/o reproducción no autorizada está prohibida, y será sancionable en la vía civil, administrativa y penal que corresponda, liberando de cualquier tipo de responsabilidad a su autor, por daños ocasionados, que sean originados por su uso indebido y/o modificación y/o alteración no autorizada.

												</p>
											</div>
										</div>

									</div>
								</div>
								<div className="w-full flex justify-center items-center bg-indigo-50 border-gray-300 px-8 py-4 rounded-lg">
									<input
										id="terms-checkbox"
										type="checkbox"
										checked={formData.usageRights}
										onChange={() => setFormData((prevData) => ({ ...prevData, usageRights: !prevData.usageRights }))}
										className="h-6 w-6 text-green-500 checked:bg-indigo-600 focus:ring-indigo-500 border-gray-300 rounded-md mt-1"
									/>
									<label htmlFor="terms-checkbox" className="ml-4 text-sm text-gray-700 leading-6">
										<span className="font-semibold text-gray-900 break-words">
											Confirmo que he leído, comprendido y acepto los términos y condiciones
										</span> del uso de la plataforma, incluyendo las políticas de privacidad.
									</label>
								</div>

							</div>
						</div>
						{/* Footer form */}
						<div className="flex w-[90%] md:w-10/12 mx-auto justify-between mt-8">
							<Button
								label={loadingForm
									? <ButtonLoader />
									: 'Regresar'
								}
								disabled={loadingForm}
								onClick={handlePrev}
							/>
							<Button
								label={loadingForm
									? <ButtonLoader />
									: 'Siguiente'
								}
								disabled={loadingForm}
								onClick={handleFinish}
							/>
						</div>
					</div>
				);
			case 3:
				return (
					<div
						className='w-full flex flex-col justify-center py-10 overflow-auto bg-white'
						style={{ height: "100dvh" }}
					>
						{/* Header form */}
						<div>
							<div className='flex justify-center items-center'>
								<div className='w-[45%] md:w-[40%]'>
									<img src={logo} alt="logo" />
								</div>
							</div>
							<div className='flex justify-center items-center mt-2'>
								<div className='w-10/12 mx-auto'>
									<h1 className='text-2xl font-bold text-gray-950  text-center'>
										Registro completado con exito
									</h1>
								</div>
							</div>
							<div className='flex justify-center items-center mt-4'>
								<div className='w-[85%] md:w-2/3'>
									<h2 className='text-sm md:text-md font-normal text-gray-600 text-center break-words'>
										Vas a recibir un correo electronico con las instruciones para realizar tu pago.
									</h2>
								</div>
							</div>
						</div>

						{/* Footer form */}
						<div className="flex w-full md:w-10/12 mx-auto justify-center mt-8">
							<div
								onClick={handleContinue}
								className="rounded-lg bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
								Continuar
								<span
									className='ml-3'
									aria-hidden="true"
								>&rarr;
								</span>
							</div>
						</div>
					</div>
				);

			default:
				return null;
		}
	};

	return (
		<div className='w-full flex'>
			<div className='w-full h-screen md:w-[40%]'>
				{renderStep()}
			</div>
			<div
				className='w-0 md:w-[60%] h-screen bg-cover'
				style={{ backgroundImage: `url(${image})` }}
			></div>
		</div>
	);
};
