import React, { useEffect, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';

import { formatDate } from '../../common/formatDateText';
import { optionsAllAccountsBank } from '../../redux/actions/options';
import { getPaymentById, updatePayment } from '../../redux/actions/payment';

import { ContainerFull } from '../../components/ContainerFull';
import { InputAccountBank } from '../../components/inputAccountBank/InputAccountBank';
import { Heading } from '../../components/Heading';
import { Wrapper } from '../../components/Wrapper';
import { Title } from '../../components/Title';
import { PropertyListItem } from '../../components/PropertyListItem';
import { PropertyItem } from '../../components/PropertyItem';
import { capitalizarPalabras } from '../../common/upperCaseWord';
import { ButtonLoader } from '../../components/buttons/ButtonLoader';
import { Button } from '../../components/buttons/Button';
import { CorfimationUpdatePaymentModal } from './CorfimationUpdatePaymentModal';


import { PhotoIcon } from '@heroicons/react/24/solid';
import { InputCurrency } from '../../components/inputs/InputCurrency';
import { ImageZoom } from '../../components/imageZoom/ImageZoom';
import { LoaderSection } from '../../components/LoaderSection';

export const PaymentUpdate = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { paymentSelected, loading: loadingSelectedPayment } = useSelector((state) => state.payment);
    const { accountsBank } = useSelector((state) => state.options);

    const cancelCreateCourseRef = useRef(null);
    const [openConfirmationUpdatePaymentModal, setOpenConfirmationUpdatePaymentModal] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        paymentId: id,
        account: '',
        amount: 0,
        publicId: '',
        file: null,
    });

    useEffect(() => {
        dispatch(getPaymentById(id))
    }, [])

    useEffect(() => {
        dispatch(optionsAllAccountsBank());
    }, []);

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            account: getIdAccount(),
        }));
    }, [accountsBank]);

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            account: getIdAccount(),
            amount: amount || '0',
            publicId: publicId,
        }));
        setPreviewImage(urlName);
    }, [paymentSelected]);

    const getIdAccount = () => {
        const accountSelected = accountsBank.find((accountBank) => accountBank.value === account);
        return { _id: accountSelected?.value, name: accountSelected?.description };
    }

    const { user, account, amount, validatedBy, paymentType, status, urlName, createdAt, publicId, subscriptionPayment } = paymentSelected;

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        // Validar el formato del archivo
        const allowedExtensions = ['jpeg', 'jpg', 'png'];
        const fileName = file.name.toLowerCase();
        const fileExtension = fileName.split('.').pop();
        const isValidExtension = allowedExtensions.includes(fileExtension);

        if (!isValidExtension) {
            toast.error('Formato de archivo no válido. Solo se permiten archivos JPEG, JPG y PNG.');
            return;
        }

        // Validar el tamaño del archivo (en bytes)
        const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
        if (file.size > maxSizeInBytes) {
            toast.error('El tamaño del archivo no puede ser mayor a 5 MB.');
            return;
        }
        // Continuar con el manejo del archivo si tanto el formato como el tamaño son válidos
        setFormData((prevData) => ({
            ...prevData,
            file: file,
        }));

        const reader = new FileReader();

        reader.onloadend = () => {
            setPreviewImage(reader.result);

        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            setPreviewImage(null);
        }
    };

    const handleRemoveImage = () => {
        setFormData((prevData) => ({
            ...prevData,
            file: null,
        }));
        setPreviewImage(null)
    }

    // const onChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    // };

    const validForm = () => {
        if (formData.amount === '') {
            toast.error('El monto ingresado no es valido');
            return false;
        }
        if (isNaN(formData.amount)) {
            toast.error('El monto ingresado no es valido');
            return false;
        }
        if (formData.account === undefined || formData.account === '') {
            toast.error('Seleccione la cuenta de pago.');
            return false;
        }
        if (previewImage === null) {
            toast.error('Seleccione su imagen de comprobante de pago.');
            return false;
        }
        return true;
    };

    const handleOpenConfirmationUpdatePaymentModal = () => {
        const isValid = validForm();
        if (isValid) {
            setOpenConfirmationUpdatePaymentModal(true);
        }
    }
    const comfirmationUpdatePayment = async () => {
        setLoading(true);
        dispatch(updatePayment(formData))
            .then((result) => {
                if (result.status === 200) {
                    toast.success(result.message);
                    setFormData((prevData) => ({
                        ...prevData,
                        account: '',
                        amount: 0,
                        publicId: '',
                        file: null,
                    }));
                    setPreviewImage(null);
                    setLoading(false);
                    navigate('/mi-perfil');
                } else {
                    toast.error('Error al subir el comprobante de pago');
                    setLoading(false);
                }
            });
    }
    
    return (
        <ContainerFull>
            <Heading
                title={`Validar pago de suscripcion`}
                subtitle={`Se debe validar el pago de la suscripcion, es importate revisar que el pago sea correcto y asignar una nueva fecha de vencimiento.`}
                center={false}
            />
            <Wrapper>
                <div className='w-full flex flex-col lg:flex-row gap-4'>
                    <div className='w-full lg:w-[50%] border-[1px] border-gray-200 rounded-lg p-4'>
                        <Title title='Informacion del cadidato' />
                        <PropertyListItem>
                            <PropertyItem
                                title={`Matricula`}
                                description={`${user?.matricula}`}
                            />
                            <PropertyItem
                                title={`Nombre completo`}
                                description={`${capitalizarPalabras(user?.firstName)} ${capitalizarPalabras(user?.lastName)}`}
                            />
                            <PropertyItem
                                title={`Email`}
                                description={user?.email}
                            />
                            <PropertyItem
                                title={`Fecha de vencimiento`}
                                description={capitalizarPalabras(formatDate(user?.paymentDeadlineDate))}
                            />
                            <PropertyItem
                                title={`Pago validado por`}
                                description={validatedBy ? capitalizarPalabras(`${validatedBy?.firstName} ${validatedBy?.lastName}`) : 'No ha sido validado'}
                            />
                            <PropertyItem
                                title={`Fecha del pago`}
                                description={capitalizarPalabras(formatDate(createdAt))}
                            />
                            <PropertyItem
                                title={`Monto de la suscripcion`}
                                description={subscriptionPayment ? `$ ${subscriptionPayment}` : `No esta definido`}
                            />
                            <PropertyItem
                                title={`Tipo de pago`}
                                description={capitalizarPalabras(paymentType)}
                            />
                            <PropertyItem
                                title={`Numero de la cuenta`}
                                description={formData.account ? formData.account.name : '-'}
                            />
                            <PropertyItem
                                title={`Estatus`}
                                description={capitalizarPalabras(status)}
                            />
                        </PropertyListItem>
                    </div>
                    <div className='w-full lg:w-[50%] border-[1px] border-gray-200 rounded-lg p-4'>
                        <Title title='Comprobante de pago' center={true} />
                        <div className='w-full mx-auto grid grid-cols-1 gap-4 p-4'>
                            {!previewImage && (
                                <div className='flex justify-center rounded-lg border border-dashed px-6 py-4 mt-2'>
                                    <div className="text-center">
                                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                        <div className="mt-4 flex justify-center items-center text-sm leading-6 text-gray-600">
                                            <label
                                                htmlFor="file-upload"
                                                className="relative cursor-pointer rounded-mdbg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>Selecciona tu comprobante de pago</span>
                                                <input
                                                    id="file-upload"
                                                    name="file-upload"
                                                    type="file"
                                                    className="sr-only"
                                                    onChange={handleFileChange}
                                                />
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">Tipos de imagen permitidos PNG, JPG y JPEG.</p>
                                        <p className="text-xs leading-5 text-gray-600">Solo se permiten imagenes con un tamaño menor a 5MB.</p>
                                    </div>
                                </div>
                            )}
                            {previewImage && (
                                <div className=''>
                                    <div className='w-full flex flex-col justify-center items-center'>
                                        {
                                            !loadingSelectedPayment
                                                ? (
                                                    <ImageZoom imageUrl={urlName} />
                                                )
                                                : (
                                                    <div className='w-full flex justify-center align-middle h-[400px]'>
                                                        <LoaderSection />
                                                    </div>
                                                )
                                        }
                                    </div>
                                    <div className="mt-6 flex items-center justify-around gap-x-6">
                                        <Button
                                            label='Selecionar otra imagen'
                                            onClick={handleRemoveImage}
                                        />
                                    </div>
                                </div>
                            )}
                            <InputCurrency
                                name={'amount'}
                                label={'Ingresa el monto pagado'}
                                formData={formData}
                                setFormData={setFormData}
                                value={formData.amount}
                                placeholder={"Ingresa el monto"}
                                disabled={false}
                            />
                            <h3 className="text-md font-semibold text-gray-900">Seleciona la cuenta de pago</h3>
                            <InputAccountBank
                                accountsBank={accountsBank}
                                accountSelected={formData}
                                setAccountSelected={setFormData}
                            />
                        </div>

                        <div className="flex w-full justify-center mt-8">

                            <Button
                                disabled={loading}
                                label={loading
                                    ?
                                    <ButtonLoader />
                                    : 'Actulizar informacion de pago'
                                }
                                onClick={() => handleOpenConfirmationUpdatePaymentModal()}
                            />
                        </div>
                    </div>
                </div>
            </Wrapper>
            <CorfimationUpdatePaymentModal
                open={openConfirmationUpdatePaymentModal}
                setOpen={setOpenConfirmationUpdatePaymentModal}
                cancelButtonRef={cancelCreateCourseRef}
                confirmAction={comfirmationUpdatePayment}
                title={'Actulizar informacion de pago'}
                message={'Estas seguro de actulizar los datos del pago de suscripcion, una vez aceptado no podras revertir esta accion.'}
                labelButonConfirm={'Confirmar informacion pago'}
                loading={loading}
            />
        </ContainerFull>
    )
}
