import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { formatDate } from '../../common/formatDateText';
import { optionsAllAccountsBank } from '../../redux/actions/options';
import { getPaymentById } from '../../redux/actions/payment';

import { ContainerFull } from '../../components/ContainerFull';
import { Heading } from '../../components/Heading';
import { Wrapper } from '../../components/Wrapper';
import { Title } from '../../components/Title';
import { ImageZoom } from '../../components/imageZoom/ImageZoom';
import { PropertyListItem } from '../../components/PropertyListItem';
import { PropertyItem } from '../../components/PropertyItem';
import { capitalizarPalabras } from '../../common/upperCaseWord';
import { InputText } from '../../components/inputs/InputText';
import { LoaderSection } from '../../components/LoaderSection';


export const PaymentDisplay = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { paymentSelected, loading: loadingSelectedPayment } = useSelector((state) => state.payment);
    const { accountsBank } = useSelector((state) => state.options);
    
    const [formData, setFormData] = useState({
        paymentId: id,
        validatedBy: '',
        account: '',
        accountNumber: '',
        paymentDeadlineDate: null,
        message: '',
        amount: '0',
    });

    useEffect(() => {
        dispatch(getPaymentById(id))
    }, [])

    useEffect(() => {
        dispatch(optionsAllAccountsBank());
    }, []);

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            account: getIdAccount(),
            accountNumber: getNumberAccount(),
        }));
    }, [accountsBank]);

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            account: getIdAccount(),
            accountNumber: getNumberAccount(),
            amount: amount || 0,
        }));
    }, [paymentSelected]);

    const getIdAccount = () => {
        const accountSelected = accountsBank.find((accountBank) => accountBank.value === account);
        return accountSelected?.value;
    }

    const getNumberAccount = () => {
        const accountSelected = accountsBank.find((accountBank) => accountBank.value === account);
        return accountSelected?.description;
    }

    const { user, account, amount, validatedBy, paymentType, status, urlName, createdAt } = paymentSelected;

    return (
        <ContainerFull>
            <Heading
                title={`Validar pago de suscripcion`}
                subtitle={`Se debe validar el pago de la suscripcion, es importate revisar que el pago sea correcto y asignar una nueva fecha de vencimiento.`}
                center={false}
            />
            <Wrapper>
                <div className='w-full flex flex-col lg:flex-row gap-4'>
                    <div className='w-full lg:w-[50%] border-[1px] border-gray-200 rounded-lg p-4'>
                        <Title title='Informacion del cadidato' />
                        <PropertyListItem>
                            <PropertyItem
                                title={`Matricula`}
                                description={`${user?.matricula}`}
                            />
                            <PropertyItem
                                title={`Nombre completo`}
                                description={`${capitalizarPalabras(user?.firstName)} ${capitalizarPalabras(user?.lastName)}`}
                            />
                            <PropertyItem
                                title={`Email`}
                                description={user?.email}
                            />
                            <PropertyItem
                                title={`Fecha de vencimiento`}
                                description={capitalizarPalabras(formatDate(user?.paymentDeadlineDate))}
                            />
                            <PropertyItem
                                title={`Pago validado por`}
                                description={capitalizarPalabras(`${validatedBy?.firstName} ${validatedBy?.lastName}`)}
                            />
                            <PropertyItem
                                title={`Fecha del pago`}
                                description={capitalizarPalabras(formatDate(createdAt))}
                            />
                            <PropertyItem
                                title={`Monto de la suscripcion`}
                                description={user?.subscriptionPayment ? `$ ${user?.subscriptionPayment}` : `No esta definido`}
                            />
                            <PropertyItem
                                title={`Tipo de pago`}
                                description={capitalizarPalabras(paymentType)}
                            />
                            <PropertyItem
                                title={`Numero de la cuenta`}
                                description={formData.accountNumber ? formData.accountNumber : '-'}
                            />
                            <PropertyItem
                                title={`Estatus`}
                                description={capitalizarPalabras(status)}
                            />
                        </PropertyListItem>
                    </div>
                    <div className='w-full lg:w-[50%] border-[1px] border-gray-200 rounded-lg p-4'>
                        <Title title='Comprobante de pago' center={true} />
                        <div className='w-full flex flex-col justify-center items-center'>
                            {
                                !loadingSelectedPayment
                                    ? (
                                        <ImageZoom imageUrl={urlName} />
                                    )
                                    : (
                                        <div className='w-full flex justify-center align-middle h-[400px]'>
                                            <LoaderSection />
                                        </div>
                                    )
                            }
                        </div>
                        <div className='w-[70%] mx-auto flex flex-col mt-8'>
                            <div className='mb-4'>
                                <InputText
                                    id={'account'}
                                    name={'account'}
                                    type={'text'}
                                    label={'Cuenta de deposito'}
                                    value={formData.accountNumber}
                                    placeholder={''}
                                    disabled={true}
                                />
                            </div>
                            <div className='mb-4'>
                                <InputText
                                    id={'amount'}
                                    name={'amount'}
                                    type={'text'}
                                    label={'Cantidad del deposito'}
                                    value={`$ ${formData.amount}`}
                                    placeholder={''}
                                    disabled={true}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </Wrapper>
        </ContainerFull>
    )
}
