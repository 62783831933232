export const SkeletonCardCourse = () => {
    return (
        <div className="w-full bg-white border border-gray-200 rounded-md shadow-sm">
            <div className="h-32 bg-gray-200 rounded-t-lg animate-blink"></div>
            <div className="w-full grid grid-cols-2 gap-4 p-4">
                <div className="w-full h-16 bg-gray-200 rounded-md animate-blink"></div>
                <div className="w-full h-16 bg-gray-200 rounded-md animate-blink"></div>
                <div className="w-full h-16 bg-gray-200 rounded-md animate-blink"></div>
                <div className="w-full h-16 bg-gray-200 rounded-md animate-blink"></div>
            </div>
            <div className="w-full grid grid-cols-3 gap-4 p-4">
                <div className="w-full h-5 bg-gray-200 rounded-md animate-blink"></div>
                <div className="w-full h-5 bg-gray-200 rounded-md animate-blink"></div>
                <div className="w-full h-5 bg-gray-200 rounded-md animate-blink"></div>
                <div className="w-full h-5 bg-gray-200 rounded-md animate-blink"></div>
                <div className="w-full h-5 bg-gray-200 rounded-md animate-blink"></div>
                <div className="w-full h-5 bg-gray-200 rounded-md animate-blink"></div>
            </div>
            <div className="w-full grid grid-cols-2 gap-4 p-4">
                <div className="w-full h-16 bg-gray-200 rounded-md animate-blink"></div>
                <div className="w-full h-16 bg-gray-200 rounded-md animate-blink"></div>
                <div className="w-full h-16 bg-gray-200 rounded-md animate-blink"></div>
                <div className="w-full h-16 bg-gray-200 rounded-md animate-blink"></div>
            </div>
            <div className="w-full grid grid-cols-3 gap-4 p-4">
                <div className="w-full h-10 bg-gray-200 rounded-md animate-blink"></div>
                <div className="w-full h-10 bg-gray-200 rounded-md animate-blink"></div>
                <div className="w-full h-10 bg-gray-200 rounded-md animate-blink"></div>
            </div>
        </div>
    )
}
