import { DrawerCustom } from '../../components/drawer/Drawer'
import { InputDateWithIcon } from '../../components/inputDateWithIcon/InputDateWithIcon';

export const DrawerFilterCandidates = ({
    isOpenDrawerFilters,
    toggleDrawerFilters,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleRemoveFilter,
    handleApllyFilter
}) => {
    return (
        <DrawerCustom
            isOpen={isOpenDrawerFilters}
            toggleDrawer={toggleDrawerFilters}
            direction='left'
            title='Filtros de la tabla de candidatos'
            subtitle='Selecciona los filtros aplicar para tabla de candidatos'
            footer={(
                <div className='flex items-center justify-end gap-4 overflow-hidden'>
                    <button
                        type='button'
                        onClick={() => handleRemoveFilter()}
                        className='rounded-md font-semibold text-md text-gray-500 bg-gray-100 hover:bg-gray-300 px-4 py-2'
                    >{"Limpiar"}</button>
                    <button
                        type='button'
                        onClick={() => toggleDrawerFilters()}
                        className='rounded-md font-semibold text-md text-gray-600 bg-white hover:bg-gray-200 px-4 py-2'
                    >{"Cancelar"}</button>
                    <button
                        type='button'
                        onClick={() => handleApllyFilter()}
                        className='rounded-md font-semibold text-md text-white bg-indigo-600 hover:bg-indigo-700 px-4 py-2'
                    >{"Aplicar"}</button>
                </div>
            )}
        >
            <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-4'>
                <div className='w-full'>
                    <InputDateWithIcon
                        id={'startDay'}
                        onChange={setStartDate}
                        value={startDate}
                        label={'Fecha de inicio'}
                        placeHolder={'Fecha de inicio'}

                    />
                </div>
                <div className='w-full'>
                    <InputDateWithIcon
                        id={'endDay'}
                        onChange={setEndDate}
                        value={endDate}
                        label={'Fecha de final'}
                        placeHolder={'Fecha de final'}
                    />
                </div>
            </div>
        </DrawerCustom>
    )
}
