import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DataGrid, esES } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { getAllPayments, cleanActionSelectedPayment } from '../../redux/actions/payment';
import { ContainerFull } from '../../components/ContainerFull';
import { Heading } from '../../components/Heading';
import { Wrapper } from '../../components/Wrapper';
import { exportToExcel } from '../../common/importDataToExcel';
import { verifyStateApp } from '../../redux/actions/stateApp';
import { DrawerFiltersPaymets } from './DrawerFiltersPaymets';
import { Loading } from '../../components/loading/Loading';
import {
	AdjustmentsHorizontalIcon,
	DocumentArrowDownIcon
} from '@heroicons/react/24/solid';
import { CheckCircleIcon, EyeIcon } from '@heroicons/react/20/solid';
import Tooltip from '@mui/material/Tooltip';
import { Zoom } from '@mui/material';


function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export const PaymentsPage = () => {
	const dispatch = useDispatch();
	const {
		payments,
		loading
	} = useSelector((state) => state.payment);

	const [isOpenDrawerFilters, setIsOpenDrawerFilters] = useState(false);
	const [formData, setFormData] = useState({
		status: '',
		paymentType: '',
		startDate: new Date('1/1/2024'),
		endDate: new Date(),
		accountNumber: '',
	});

	const { user } = useSelector((state) => state.user);
	const { typeUser } = user;

	const typeUserCanValidate = ['director', 'cobranza', 'ventas'];

	useEffect(() => {
		dispatch(cleanActionSelectedPayment())
	}, [dispatch]);

	useEffect(() => {
		dispatch(getAllPayments({
			status: 'allStatus',
			paymentType: 'allTypes',
			startDate: new Date('1/1/2024'),
			endDate: new Date(),
			accountNumber: 'allAccountNumbers',
		}));
	}, []);

	const toggleDrawerFilters = () => {
		setIsOpenDrawerFilters((prevState) => !prevState);
	}

	const [searchText, setSearchText] = useState('');

	const rows = payments;

	const columns = [
		{
			field: 'matricula',
			flex: 1,
			headerName: 'Matricula',
			renderCell: (params) => (
				<Link className='text-blue-700 underline' to={`/perfil/${params.row.idUser}`}>{params.value}</Link>
			)
		},
		{
			field: 'status',
			headerName: 'Estatus',
			width: 150,
			// flex: 1,
			renderCell: (params) => (
				<div className='w-full flex justify-center items-center'>
					<div className={classNames(
						params.value === 'validado'
							? ' ring-lime-600 shadow-lime-600 bg-lime-600'
							: params.value === 'pendiente' ? ' ring-orange-500 shadow-orange-500 bg-orange-500'
								: params.value === 'rechazado' ? ' ring-red-500 shadow-red-500 bg-red-500'
									: null,
						' text-white flex justify-center border-none w-[100px] px-1 py-1 ring-2 shadow-md rounded-xl uppercase'
					)}>
						<span className="text-[.7rem] font-thin shadow-inherit tracking-wider capitalize">{params.value}</span>
					</div>
				</div>
			),
		},
		{
			field: 'action',
			headerName: '',
			width: 150,
			// flex: 1,
			columnResize: true,
			sortable: false,
			disableColumnMenu: true,
			renderCell: (params) =>
			(
				<div className='w-full flex justify-center gap-4'>
					{
						(params.row.status === 'pendiente') && typeUserCanValidate.includes(typeUser)
							?
							(
								<Tooltip title="Validar pago" TransitionComponent={Zoom} placement='left'>
									<Link
										to={`/validar-pago/${params.id}`}
										className="flex justify-center shadow-sm shadow-indigo-600 bg-indigo-600 uppercase px-4 py-2 text-slate-50 rounded hover:bg-indigo-800"
									>
										<CheckCircleIcon className="block h-5 w-5" aria-hidden="true" />
									</Link>
								</Tooltip>
							)
							: params.row.status === 'validado' || params.row.status === 'rechazado'
								?
								(
									<Tooltip title="Ver detalle de pago" TransitionComponent={Zoom} placement='left'>
										<Link
											to={`/detalle-pago/${params.id}`}
											className="flex justify-center shadow-sm shadow-sky-600 bg-sky-600 px-4 py-2 uppercase text-slate-50 rounded hover:bg-sky-800"
										>
											<EyeIcon className="block h-5 w-5" aria-hidden="true" />
										</Link>
									</Tooltip>
								)
								: null
					}
				</div>
			),
		},
		{
			field: 'nameUser',
			headerName: 'Nombre',
			width: 250,
			// flex: 1,
			renderCell: (params) => (
				<p className='capitalize'>{params.value}</p>
			),
		},
		{
			field: 'paymentDeadlineDate',
			headerName: 'Fecha limite de pago',
			width: 250,
			// flex: 1,
			renderCell: (params) => (
				<p className='capitalize'>{params.value}</p>
			),
		},
		{
			field: 'validatedBy',
			headerName: 'Validado por',
			width: 200,
			// flex: 1,
			renderCell: (params) => (
				<p className='capitalize'>{params.value}</p>
			),
		},
		{
			field: 'accountName',
			headerName: 'Cuenta bancaria',
			width: 200,
			// flex: 1,
			renderCell: (params) => (
				<p className='capitalize'>{params.value}</p>
			),
		},
		{
			field: 'accountNumber',
			headerName: 'Numero de cuenta',
			width: 200,
			// flex: 1,
			renderCell: (params) => (
				<p>{params.value}</p>
			),
		},
		{
			field: 'paymentType',
			headerName: 'Tipo de pago',
			width: 150,
			// flex: 1,
			renderCell: (params) => (
				<p className='capitalize'>{params.value}</p>
			),
		},
		{
			field: 'amount',
			headerName: 'Monto',
			columnResize: true,
			width: 150,
			// flex: 1,
			renderCell: (params) => (
				<p>{`$ ${params.value}`}</p>
			),
		},
		{
			field: 'formatUpdatedAt',
			headerName: 'Ultima actulizacion',
			resizable: true,
			width: 250,
			// flex: 1,
			renderCell: (params) => (
				<div className='w-full flex justify-center'>
					<p className='capitalize'>
						{params.value}
					</p>
				</div>
			),
		},
		{
			field: 'openNotesCount',
			headerName: 'Notas',
			width: 150,
			// flex: 1,
			renderCell: (params) => (
				<div className={classNames(`${params.value >= 1 ? 'flex' : 'hidden'} w-full justify-center items-center`)}>
					<div className='bg-rose-500 font-bold mt-1 text-white text-center px-2.5 py-1 rounded-2xl'>{params.value}</div>
				</div>
			),
		},
	];

	const handleSearchChange = (event) => {
		const searchText = event.target.value;
		setSearchText(searchText);
	};

	const filteredRows = rows.filter(
		(row) =>
			row.matricula?.toString().toLowerCase().includes(searchText.toString().toLowerCase()) ||
			row.nameUser?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.paymentDeadlineDate?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.validatedBy?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.paymentType?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.accountName?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.accountNumber.includes(searchText.toLowerCase()) ||
			row.amount?.toString().toLowerCase().includes(searchText.toString().toLowerCase()) ||
			row.formatUpdatedAt?.toLowerCase().includes(searchText.toLowerCase()) ||
			row.status?.toLowerCase().includes(searchText.toLowerCase())
	);

	const formatDataExcel = async (data) => {
		const formattedData = data.map((item) => ({
			id: item.id,
			matricula: item.matricula,
			nombre: item.nameUser,
			fecha_limite_de_pago: item.paymentDeadlineDate,
			validado_por: item.validatedBy,
			fecha_registro: item.formatCreatedAt,
			fecha_de_ultima_modificacion: item.formatUpdatedAt,
			status: item.status,
			accountName: item.accountName,
			accountNumber: item.accountNumber,
			tipo_de_pago: item.paymentType,
			monto: item.amount,
		}));

		const totalAmount = data.reduce((sum, payment) => sum + payment.amount, 0);

		formattedData.push({
			id: '',
			matricula: '',
			nombre: '',
			fecha_limite_de_pago: '',
			validado_por: '',
			fecha_registro: '',
			fecha_de_ultima_modificacion: '',
			status: '',
			accountName: '',
			accountNumber: '',
			tipo_de_pago: 'Total',
			monto: totalAmount,
		});
		return formattedData;
	}

	const handleImportData = async (data, name) => {
		// console.log(data);
		dispatch(verifyStateApp())
			.then((response) => {
				if (response.data === false) {
					toast.error(response.message)

				} else {
					confirDownloadFile(data, name);
				}
			});
	}

	const confirDownloadFile = async (data, name) => {
		const dataReady = await formatDataExcel(data);
		const response = exportToExcel(dataReady, name);
		response.data ? toast.error(response.message) : toast.success(response.message);
	}

	const handleApllyFilters = (payload) => {
		dispatch(getAllPayments(payload));
		toggleDrawerFilters();
	}

	const handleRemoveFilter = () => {
		setFormData({
			status: '',
			paymentType: '',
			startDate: new Date('1/1/2024'),
			endDate: new Date(),
			accountNumber: '',
		});
		dispatch(getAllPayments({
			status: 'allStatus',
			paymentType: 'allTypes',
			startDate: new Date('1/1/2024'),
			endDate: new Date(),
			accountNumber: 'allAccountNumbers',
		}));
		toggleDrawerFilters();
	}

	return (
		<ContainerFull>
			<Heading
				title={`Historial de pagos`}
				subtitle={`Muestra el historial de pagos realizados por los alumnos, puedes validar los pagos pendientes o rechazados.`}
				center={false}
			/>

			<div className='w-full flex justify-end mt-4 gap-4'>
				<Tooltip title="Panel de filtros" TransitionComponent={Zoom} placement='top'>
					<button
						type='button'
						onClick={() => toggleDrawerFilters()}
						className='rounded-md px-4 py-2 font-semibold text-md text-white bg-indigo-600 shadow-sm shadow-indigo-600 hover:bg-indigo-800'
					><AdjustmentsHorizontalIcon className="block h-5 w-5" aria-hidden="true" /></button>
				</Tooltip>
				<Tooltip title="Descargar archivo de Excel" TransitionComponent={Zoom} placement='top'>
					<button
						type='button'
						onClick={() => handleImportData(payments, 'Lista_de_pagos.xlsx')}
						className='rounded-md px-4 py-2 font-semibold text-md text-white bg-indigo-600 shadow-sm shadow-indigo-600 hover:bg-indigo-800'
					><DocumentArrowDownIcon className="block h-5 w-5" aria-hidden="true" /></button>
				</Tooltip>
			</div>

			{payments && (
				<>
					<Wrapper>
						<input
							type="text"
							placeholder="Buscar un registro de pago"
							value={searchText}
							onChange={handleSearchChange}
							className='block w-full rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' />
					</Wrapper>
					<Wrapper>
						<div className="overflow-x-auto">
							{
								loading
									? (
										<Loading />
									)
									: (
										<>
											{
												rows.length > 0
													? (
														<div className='overflow-x-auto'>
															<DataGrid
																rows={filteredRows}
																columns={columns}
																// onRowClick={handleRowClick}
																localeText={esES.components.MuiDataGrid.defaultProps.localeText}
																initialState={{
																	pagination: {
																		paginationModel: { pageSize: 10, page: 0, },
																	},
																}}
																pageSizeOptions={[10, 15, 25]}
															// sortingOrder={['desc', 'asc']}
															// sortModel={[
															// 	{
															// 		field: 'formatUpdatedAt',
															// 		sort: 'desc',
															// 	},
															// ]}

															/>
														</div>
													) : (
														<p className='text-gray-400'>No hay registros de pagos</p>
													)
											}
										</>
									)
							}
						</div>
					</Wrapper>
				</>
			)}
			<DrawerFiltersPaymets
				isOpenDrawerFilters={isOpenDrawerFilters}
				toggleDrawerFilters={toggleDrawerFilters}
				formData={formData}
				setFormData={setFormData}
				handleApllyFilters={handleApllyFilters}
				handleRemoveFilter={handleRemoveFilter}
			/>
		</ContainerFull>
	);
}
