import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'


export const DropDown = ({ title, options, formData, setFormData, property }) => {
    
    const onChange = (value) => {        
        setFormData({ ...formData, [property]: value });
    };
    
    return (
        <div className="flex text-center justify-center">
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="inline-flex w-full justify-center rounded-md bg-indigo-700 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-500">
                        {title}
                        <ChevronDownIcon
                            className="-mr-1 ml-2 h-5 w-5 text-violet-200 hover:text-violet-100"
                            aria-hidden="true"
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                        <div className="px-1 py-1">
                            {
                                options.map(option => (

                                    <Menu.Item key={option.value}>
                                        {({ active }) => (
                                            <button
                                                className={`${option.value === formData[property]?.value ? 'bg-indigo-700 text-white' : 'bg-white text-gray-900'
                                                    } hover:bg-indigo-700 hover:text-white group flex w-full items-center rounded-md px-2 py-2 text-sm my-2`}
                                                onClick={() => onChange(option)}
                                            >
                                                {option.label}
                                            </button>
                                        )}
                                    </Menu.Item>
                                ))
                            }
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}