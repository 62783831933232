import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { checkStatusInvoice, uploadPayment } from '../../redux/actions/payment';
import { optionsAllAccountsBank } from '../../redux/actions/options';

import { toast } from 'react-hot-toast';
import { Button } from '../../components/buttons/Button';
import { ButtonLoader } from '../../components/buttons/ButtonLoader';
import { InputAccountBank } from '../../components/inputAccountBank/InputAccountBank';

import { PhotoIcon } from '@heroicons/react/24/solid';
import { Wrapper } from '../../components/Wrapper';
import { ContainerFull } from '../../components/ContainerFull';
import { Heading } from '../../components/Heading';
import { InputSwitch } from '../../components/inputSwitch/InputSwitch';
import { InputCurrency } from '../../components/inputs/InputCurrency';


export const PaymentUpload = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activateInvoice, setActivateInvoice] = useState(false);
    const [checkActivateInvoice, setCheckActivateInvoice] = useState(false);
    const { accountsBank } = useSelector((state) => state.options);
    const { user } = useSelector((state) => state.user);
    const { _id: userId } = user;
    const [previewImage, setPreviewImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        amount: '',
        account: '',
        userId: '',
        file: null,
        invoiceActive: activateInvoice,
    });

    useEffect(() => {
        dispatch(optionsAllAccountsBank());
    }, []);

    useEffect(() => {
        dispatch(checkStatusInvoice(userId))
            .then((result) => {
                if (result.status === 200) {
                    setCheckActivateInvoice(result.data.invoiceStatus);
                    setActivateInvoice(result.data.invoiceActive);
                }
            });
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        // Validar el formato del archivo
        const allowedExtensions = ['jpeg', 'jpg', 'png'];
        const fileName = file.name.toLowerCase();
        const fileExtension = fileName.split('.').pop();
        const isValidExtension = allowedExtensions.includes(fileExtension);

        if (!isValidExtension) {
            toast.error('Formato de archivo no válido. Solo se permiten archivos JPEG, JPG y PNG.');
            return;
        }

        // Validar el tamaño del archivo (en bytes)
        const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
        if (file.size > maxSizeInBytes) {
            toast.error('El tamaño del archivo no puede ser mayor a 5 MB.');
            return;
        }

        // Continuar con el manejo del archivo si tanto el formato como el tamaño son válidos
        setFormData((prevData) => ({
            ...prevData,
            file: file,
        }));

        const reader = new FileReader();

        reader.onloadend = () => {
            setPreviewImage(reader.result);

        };

        if (file) {
            reader.readAsDataURL(file);
        } else {
            setPreviewImage(null);
        }
    };

    const handleRemoveImage = () => {
        setFormData((prevData) => ({
            ...prevData,
            file: null,
        }));
        setPreviewImage(null)
    }

    // const onChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    // };

    const validForm = (updateState) => {
        if (updateState.file === null) {
            toast.error('Seleccione su comprobante de pago.');
            return false;
        }
        if (updateState.amount === '') {
            toast.error('El monto ingresado no es valido');
            return false;
        }
        if (isNaN(updateState.amount)) {
            setFormData((prevData) => ({
                ...prevData,
                'amount': '',
            }));
            toast.error('El monto ingresado no es valido');
            return false;
        }
        if (updateState.account === undefined || updateState.account === '') {
            toast.error('Seleccione la cuenta de pago.');
            return false;
        }
        return true;
    };

    const handeleSubmitUploadPayment = () => {
        setLoading(true);
        const updateState = { ...formData }
        updateState.userId = userId;
        updateState.account = formData.account._id;
        updateState.invoiceActive = activateInvoice;
        const isValid = validForm(updateState);
        if (isValid) {
            dispatch(uploadPayment(updateState))
                .then((result) => {
                    if (result.status === 201) {
                        toast.success(result.message);
                        setFormData((prevData) => ({
                            ...prevData,
                            amount: '',
                            account: '',
                            userId: '',
                            file: null,
                        }));
                        setPreviewImage(null);
                        setLoading(false);
                        navigate('/mi-perfil')
                    } else {
                        toast.error('Error al subir el comprobante de pago');
                        setLoading(false);
                    }
                });
        } else {
            setLoading(false);
        }
    }
    return (
        <ContainerFull>
            <Heading
                title={'Subir comprobante de pago'}
                center={false}
            />
            <Wrapper>
                <div className='w-full flex flex-collg:flex-row my-4'>
                    <div className='w-full lg:w-[50%] border border-gray-200 mx-auto rounded-md px-8 py-4'>
                        <div className='flex items-center mt-8'>
                            <div className='w-full mx-auto grid grid-cols-1 gap-y-4'>
                                {!previewImage && (
                                    <div className='flex justify-center rounded-lg border border-dashed px-6 py-10 mt-2'>
                                        <div className="text-center">
                                            <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                            <div className="mt-4 flex justify-center items-center text-sm leading-6 text-gray-600">
                                                <label
                                                    htmlFor="file-upload"
                                                    className="relative cursor-pointer rounded-mdbg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                                >
                                                    <span>Selecciona tu comprobante de pago</span>
                                                    <input
                                                        id="file-upload"
                                                        name="file-upload"
                                                        type="file"
                                                        className="sr-only"
                                                        onChange={handleFileChange}
                                                    />
                                                </label>
                                            </div>
                                            <p className="text-xs leading-5 text-gray-600">Tipos de imagen permitidos PNG, JPG y JPEG.</p>
                                            <p className="text-xs leading-5 text-gray-600">Solo se permiten imagenes con un tamaño menor a 5MB.</p>
                                        </div>
                                    </div>
                                )}
                                {previewImage && (
                                    <div className=''>
                                        <img
                                            src={previewImage}
                                            alt="Comprobante de pago"
                                            className="mt-4 mx-auto  w-1/3"
                                        />
                                        <div className="mt-6 flex items-center justify-around gap-x-6">
                                            <Button
                                                label='Selecionar otra imagen'
                                                onClick={handleRemoveImage}
                                            />
                                        </div>
                                    </div>
                                )}
                                <InputCurrency
                                    id={'amount'}
                                    name={'amount'}
                                    type={'text'}
                                    label={'Ingresa el monto pagado'}
                                    formData={formData}
                                    setFormData={setFormData}
                                    value={formData.amount}
                                    placeholder={"Ingresa el monto"}
                                    disabled={false}
                                />
                                <h3 className="text-md font-semibold text-gray-900">Seleciona la cuenta de pago</h3>
                                <InputAccountBank
                                    accountsBank={accountsBank}
                                    accountSelected={formData}
                                    setAccountSelected={setFormData}
                                />
                                <div>

                                </div>

                                <div className='w-full flex flex-col align-middle ring-1 ring-indigo-900/10 rounded-sm px-4 pt-2 pb-6 shadow-sm '>
                                    <InputSwitch
                                        enabled={activateInvoice}
                                        setEnabled={setActivateInvoice}
                                        label={activateInvoice ? "Desactivar Facturacion Automatica" : "Activar Facturacion Automatica"}
                                        disabled={checkActivateInvoice}
                                    />
                                    {
                                        checkActivateInvoice && (
                                            <p className='text-sm text-gray-900 tracking-normal'>Si deseas activar la facturacion automatica, debes registrar tus datos fiscales en tu <Link className='font-bold text-md text-indigo-600' to={"https://cfamex-plataforma.com/mi-perfil"}>Perfil</Link></p>
                                        )
                                    }
                                    {
                                        activateInvoice ? (
                                            <p className='text-sm text-gray-900 tracking-normal'>Desactivar facturacion automatica. Esto cancelara el envio de facuras al correo electronico.</p>
                                        ) : !checkActivateInvoice ? (

                                            <p className='text-sm text-gray-900 tracking-normal'>Activar facturacion automatica. Esto permitira que se envien las facturas al correo electronico.</p>
                                        ) : null

                                    }
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full justify-between mt-8">

                            <Button
                                disabled={loading}
                                label={'Cancelar'}
                                onClick={() => navigate('/mi-perfil')}
                            />
                            <Button
                                disabled={loading}
                                label={loading
                                    ?
                                    <ButtonLoader />
                                    : 'Subir comprobante de pago'
                                }
                                onClick={handeleSubmitUploadPayment}
                            />
                        </div>
                    </div>
                </div>
            </Wrapper>
        </ContainerFull>
    )
}
