import React, { useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import "./styles-drawer.css";

export const DrawerCustom = ({
    isOpen,
    toggleDrawer,
    children,
    direction = 'right',
    sizeSM = '100%',
    sizeMD = '50%',
    sizeLG = '35%',
    title,
    subtitle,
    footer,
}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            direction={direction}
            size={windowWidth < 868 ? sizeSM : windowWidth <= 1024 ? sizeMD : sizeLG}
            overlayOpacity={0.4}
            style={{
                backgroundColor: 'transparent',
                height: '100dvh',
                boxShadow: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: `${direction === 'left' & windowWidth > 868 ? '15px' : '0px'}`,
                marginRight: `${direction === 'right' & windowWidth > 868 ? '15px' : '0px'}`,
            }}
        >
            <div
                className={`${windowWidth > 868 ? "w-[100%]" : "w-[96%]"} flex flex-col rounded-lg bg-white py-2 px-4 shadow-md`}
                style={{ height: "96vh", height: "96dvh" }}
            >
                <section className="flex flex-col h-full">
                    {/* Header con tamaño fijo */}
                    <header className="w-full text-gray-900 p-4 flex items-center justify-between border-b border-gray-200">
                        <div className='w-full'>
                            <h1 className="text-xl font-bold truncate">{title}</h1>
                            <p className="text-sm font-light truncate">{subtitle}</p>
                        </div>
                    </header>
                    {/* Contenido que ocupa el espacio restante */}
                    <main className="flex-1 py-4 px-4 overflow-auto">
                        {children}
                    </main>
                    {/* Footer con tamaño fijo */}
                    <footer className="p-4 border-t border-gray-200">
                        {footer}
                    </footer>
                </section>
                {/* {children} */}
            </div>
        </Drawer>
    )
}
