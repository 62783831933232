
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';


export const ImageZoom = ({ imageUrl }) => {

	return (
		<Zoom>
			<img
				alt=""
				src={imageUrl}
				width="200px"
			/>
		</Zoom>
	);
};
