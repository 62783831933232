
export const formatDate = (inputDate) => {
	if (!inputDate) {
		return "";
	}
	const options = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	};

	const date = new Date(inputDate);
	return date.toLocaleDateString('es-ES', options);
}

export const formatDateTime = (inputDate) => {
	if (!inputDate) {
		return "";
	}
	const options = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric', // Puedes eliminar esta línea si no necesitas los segundos
		hour12: false, // Usa false para formato 24 horas, true para 12 horas
	};

	const date = new Date(inputDate);
	return date.toLocaleString('es-ES', options);
}

export const toDayDate = () => {
	const options = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	};
	const date = new Date();
	return date.toLocaleDateString('es-ES', options);
}


export const validateSubscription = (date) => {
	if (!date) return false;

	// Convertir a Date y validar si es una fecha válida
	const inputDate = new Date(date);
	if (isNaN(inputDate.getTime())) return "La fecha no es válida.";

	// Obtener la fecha actual en la zona horaria de Ciudad de México
	const now = new Date();
	const timeZone = "America/Mexico_City";

	// Obtener solo el año, mes y día en la zona horaria de México
	const formatDate = (date) => {
		return new Intl.DateTimeFormat("en-CA", {
			timeZone,
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
		}).format(date);
	};

	const inputDateStr = formatDate(inputDate);
	const todayStr = formatDate(now);

	// Convertir las fechas formateadas de nuevo a Date para comparación (sin horas)
	const inputDateMX = new Date(`${inputDateStr}T00:00:00`);
	const todayMX = new Date(`${todayStr}T00:00:00`);

	// Comparar fechas (ignorando horas)
	return inputDateMX >= todayMX;
};

export const isDateGreaterThanToday = (date) => {
	// Create a Date object with the current date
	const currentDate = new Date();

	// Create a Date object with the provided date
	const enteredDate = new Date(date);

	// Compare the dates
	return enteredDate > currentDate;
}

export const isDateGreaterThanTarget = (date, targetDate) => {
	// if (!targetDate) {
	// 	const currentDate = new Date();
	// }
	// Create a Date object with the provided date
	const currentDate = new Date(targetDate);
	const enteredDate = new Date(date);

	// Compare the dates
	return enteredDate > currentDate;
}