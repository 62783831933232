import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { Button } from '../../components/buttons/Button';

import { validatePaymentVoucher } from '../../redux/actions/preRegistration';
import { resetEmailVerification } from '../../redux/reducers/preRegistration';
import { optionsAllAccountsBank, optionsCoordinadors } from '../../redux/actions/options';

import { PhotoIcon } from '@heroicons/react/24/solid';
import logo from '../../static/image/logo.png';
import { ButtonLoader } from '../../components/buttons/ButtonLoader';
import { ComboBox } from '../../components/comboBox/ComboBox';
import { InputAccountBank } from '../../components/inputAccountBank/InputAccountBank'
import image from '../../static/image/11.png';
import { InputCurrency } from '../../components/inputs/InputCurrency';


export const FormValidacionPago = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [findCoordinador, setFindCoordinador] = useState('');
	const { coordinadors, accountsBank } = useSelector((state) => state.options);

	const [step, setStep] = useState(1);

	const [previewImage, setPreviewImage] = useState(null);
	const [loadingForm, setLoadingForm] = useState(false);

	const {
		loading,
		email,
		success,
		userPreRegister
	} = useSelector((state) => state.preRegistration)

	useEffect(() => {
		if (email === '') {
			navigate(`/pre-registro`);
		}
	});

	useEffect(() => {
		dispatch(optionsCoordinadors());
		dispatch(optionsAllAccountsBank());
	}, []);

	useEffect(() => {
		if (success) {
			setStep(2)
		}
	}, [success]);

	const [formData, setFormData] = useState({
		amount: '',
		coordinador: '',
		account: '',
		file: null,
		email: email,
		id: userPreRegister?._id
	});

	const handleRemoveImage = () => {
		setFormData((prevData) => ({
			...prevData,
			file: null,
		}));
		setPreviewImage(null)
	}

	// const onChange = (e) => {
	// 	const { name, value } = e.target;
	// 	setFormData((prevData) => ({
	// 		...prevData,
	// 		[name]: value,
	// 	}));
	// };

	const handeleSubmitValidationPayment = () => {
		setLoadingForm(true);
		const updateState = { ...formData }
		updateState.coordinador = formData.coordinador.value || '';
		updateState.account = formData.account._id || '';
		const isValid = validForm(updateState);
		if (isValid) {
			dispatch(validatePaymentVoucher(updateState))
				.then((result) => {
					if (result.status === 200) {
						toast.success(result.message);
					}
					setLoadingForm(false);
				});
		} else {
			setLoadingForm(false);
		}
	}

	const validForm = (updateState) => {
		if (updateState.amount === '') {
			toast.error('El monto ingresado no es valido');
			return false;
		}
		if (isNaN(updateState.amount)) {
			setFormData((prevData) => ({
				...prevData,
				'amount': '',
			}));
			toast.error('El monto ingresado no es valido');
			return false;
		}
		if (updateState.file === null) {
			toast.error('Seleccione su comprobante de pago.');
			return false;
		}
		if (updateState.coordinador.trim() === '') {
			toast.error('Seleccione a su coordinador.');
			return false;
		}
		if (updateState.account.trim() === '') {
			toast.error('Seleccione la cuenta de pago.');
			return false;
		}
		return true;
	};

	const handleFileChange = (event) => {
		const file = event.target.files[0];

		// Validar el formato del archivo
		const allowedExtensions = ['jpeg', 'jpg', 'png'];
		const fileName = file.name.toLowerCase();
		const fileExtension = fileName.split('.').pop();
		const isValidExtension = allowedExtensions.includes(fileExtension);

		if (!isValidExtension) {
			toast.error('Formato de archivo no válido. Solo se permiten archivos JPEG, JPG y PNG.');
			return;
		}

		// Validar el tamaño del archivo (en bytes)
		const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
		if (file.size > maxSizeInBytes) {
			toast.error('El tamaño del archivo no puede ser mayor a 5 MB.');
			return;
		}

		// Continuar con el manejo del archivo si tanto el formato como el tamaño son válidos
		setFormData((prevData) => ({
			...prevData,
			file: file,
		}));

		const reader = new FileReader();

		reader.onloadend = () => {
			setPreviewImage(reader.result);

		};

		if (file) {
			reader.readAsDataURL(file);
		} else {
			setPreviewImage(null);
		}
	};


	const handleContinue = () => {
		dispatch(resetEmailVerification())
		navigate(`/pre-registro`);
	}

	const filteredCoordinadors = findCoordinador === ''
		? coordinadors
		: coordinadors.filter((coordinador) =>
			coordinador.description
				.toLowerCase()
				.replace(/\s+/g, '')
				.includes(findCoordinador.toLowerCase().replace(/\s+/g, ''))
		);


	const renderStep = () => {
		switch (step) {
			case 1:
				return (
					<div
						className='w-full flex flex-col justify-between py-10 overflow-auto bg-white'
						style={{ height: "100dvh" }}
					>
						{/* Header form */}
						<div>
							<div className='flex justify-center items-center'>
								<div className='w-[45%] md:w-[40%]'>
									<img src={logo} alt="logo" />
								</div>
							</div>
							<div className='flex justify-center items-center mt-2'>
								<div className='w-10/12 mx-auto'>
									<h1 className='text-2xl font-bold text-gray-950  text-center'>
										Subir comprobante de pago
									</h1>
								</div>
							</div>
							<div className='flex justify-center items-center mt-4'>
								<div className='w-[85%] md:w-2/3'>
									<h2 className='text-sm md:text-md font-normal text-gray-600 text-center break-words'>
										Por favor agregue tu comprobante de pago para validarlo.
									</h2>
								</div>
							</div>
						</div>
						{/* Body form */}
						<div className='flex items-center mt-8'>
							<div className='w-[90%] md:w-10/12 mx-auto grid grid-cols-1 gap-y-4'>
								<h3 className="text-md font-semibold text-gray-900">Comprobante de pago</h3>
								{!previewImage && (
									<div className='flex justify-center rounded-lg border border-dashed px-6 py-10 mt-2'>
										<div className="text-center">
											<PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
											<div className="mt-4 flex justify-center items-center text-sm leading-6 text-gray-600">
												<label
													htmlFor="file-upload"
													className="relative cursor-pointer rounded-mdbg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
												>
													<span>Selecciona tu comprobante de pago</span>
													<input
														id="file-upload"
														name="file-upload"
														type="file"
														className="sr-only"
														onChange={handleFileChange}
													/>
												</label>
											</div>
											<p className="text-xs leading-5 text-gray-600">Tipos de imagen permitidos PNG, JPG y JPEG.</p>
											<p className="text-xs leading-5 text-gray-600">Solo se permiten imagenes con un tamaño menor a 5MB.</p>
										</div>
									</div>
								)}
								{previewImage && (
									<div className=''>
										<img
											src={previewImage}
											alt="Comprobante de pago"
											className="mt-4 mx-auto  w-1/3"
										/>
										<div className="mt-6 flex items-center justify-around gap-x-6">
											<Button
												label='Selecionar otra imagen'
												disabled={loading}
												onClick={handleRemoveImage}
											/>
										</div>
									</div>
								)}
								{/* <InputText
									id={'amount'}
									name={'amount'}
									type={'text'}
									label={'Ingresa el monto pagado'}
									onChange={(e) => onChange(e)}
									value={formData.amount}
									placeholder={'Ingresa el monto pagado'}
									disabled={false}
								/> */}
								<InputCurrency
									name={'amount'}
									label={'Ingresa el monto pagado'}
									formData={formData}
									setFormData={setFormData}
									value={formData.amount}
									placeholder={"Ingresa el monto"}
									disabled={false}
								/>
								<h3 className="text-md font-semibold text-gray-900 mt-4">Seleciona a tu coordinador</h3>
								<ComboBox
									filterData={filteredCoordinadors}
									query={findCoordinador}
									setQuery={setFindCoordinador}
									selected={formData}
									setSelected={setFormData}
									placeholder='Selecione a tu coordinador'
									property='coordinador'
								/>
								<h3 className="text-md font-semibold text-gray-900 mt-4">Seleciona la cuenta de pago</h3>
								<InputAccountBank
									accountsBank={accountsBank}
									accountSelected={formData}
									setAccountSelected={setFormData}
									numColumns={1}
								/>
							</div>
						</div>
						<div className="flex w-[95%] md:w-11/12 justify-end mt-8">
							<Button
								disabled={loadingForm}
								label={loadingForm
									?
									<ButtonLoader />
									: 'Siguiente'
								}
								onClick={handeleSubmitValidationPayment}
							/>
						</div>

					</div>
				)
			case 2:
				return (
					<div
						className='w-full flex flex-col justify-center py-10 overflow-auto bg-white'
						style={{ height: "100dvh" }}
					>
						{/* Header form */}
						<div>
							<div className='flex justify-center items-center'>
								<div className='w-[45%] md:w-[40%]'>
									<img src={logo} alt="logo" />
								</div>
							</div>
							<div className='flex justify-center items-center mt-2'>
								<div className='w-10/12 mx-auto'>
									<h1 className='text-2xl font-bold text-gray-950  text-center'>
										Tu comprobante se ha subido con exito.
									</h1>
								</div>
							</div>
							<div className='flex justify-center items-center mt-4'>
								<div className='w-[85%] md:w-2/3'>
									<h2 className='text-sm md:text-md font-normal text-gray-600 text-center break-words'>
										Tu comprobante se ha subido con exito.
									</h2>
								</div>
							</div>
						</div>

						{/* Footer form */}
						<div className="flex w-full md:w-10/12 mx-auto justify-center mt-8">
							<div
								onClick={handleContinue}
								className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
								Continuar
								<span
									className='ml-3'
									aria-hidden="true"
								>&rarr;
								</span>
							</div>
						</div>
					</div>
				);

			default:
				return null;
		}
	}

	return (
		<div className='w-full flex'>
			<div
				className='w-0 md:w-[65%] h-screen bg-cover'
				style={{ backgroundImage: `url(${image})` }}
			></div>
			<div className='w-full h-screen md:w-[35%]'>
				{renderStep()}
			</div>
		</div>
	)
}
