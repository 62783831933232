import React, { useEffect, useRef, useState } from 'react'
import { DrawerCustom } from '../../components/drawer/Drawer'
import { InputDateWithIcon } from '../../components/inputDateWithIcon/InputDateWithIcon';
import { ComboBox } from '../../components/comboBox/ComboBox';
import { useDispatch, useSelector } from 'react-redux';
import { optionsAllCourseIdentifier, optionsAllTeachers, optionsLanguages, optionsLevels } from '../../redux/actions/options';
import { listDays, statusCourse } from '../../static/data';
import { InputCheckboxGroup } from '../../components/inputCheckBoxGroup/InputCheckboxGroup';

export const DrawerFiltersCourse = ({
    isOpenDrawerFilters,
    toggleDrawerFilters,
    formData,
    setFormData,
    handleApllyFilters,
    handleRemoveFilter
}) => {
    const dispatch = useDispatch();
    const savedFilterData = JSON.parse(localStorage.getItem('filtersCourses'));
    const { teachers, levels, languages, identifiers } = useSelector((state) => state.options);
    const [startDate, setStartDate] = useState(formData.fromDate ? formData.fromDate : '');
    const [endDate, setEndDate] = useState(formData.toDate ? formData.toDate : '');
    const [findCourseIdentifier, setFindCourseIdenfier] = useState('');
    const [findTeacher, setFindTeacher] = useState('');
    const [selectedDays, setSelectedDays] = useState([]);
    const [selectedLanguage, setSeletedLanguage] = useState([]);
    const [selectedstatus, setSeletedstatus] = useState([]);
    const [selectedNivel, setSeletedNivel] = useState([]);

    const checkboxGroupRefDays = useRef(null);
    const checkboxGroupRefStatus = useRef(null);
    const checkboxGroupRefLanguage = useRef(null);
    const checkboxGroupRefNivel = useRef(null);

    useEffect(() => {
        if (savedFilterData) {
            if (savedFilterData.days) setSelectedDays(savedFilterData.days);
            if (savedFilterData.language) setSeletedLanguage(savedFilterData.language);
            if (savedFilterData.level) setSeletedNivel(savedFilterData.level);
            if (savedFilterData.status) setSeletedstatus(savedFilterData.status);
            if (savedFilterData.fromDate) setStartDate(savedFilterData.fromDate);
            if (savedFilterData.toDate) setEndDate(savedFilterData.toDate);

            checkboxGroupRefDays.current.updateSelection(savedFilterData.days);
            checkboxGroupRefStatus.current.updateSelection(savedFilterData.status);
            checkboxGroupRefLanguage.current.updateSelection(savedFilterData.language);
            checkboxGroupRefNivel.current.updateSelection(savedFilterData.level);
        }
    }, []);

    useEffect(() => {
        dispatch(optionsAllTeachers());
        dispatch(optionsLevels());
        dispatch(optionsLanguages());
        dispatch(optionsAllCourseIdentifier());
    }, [dispatch]);

    useEffect(() => {
        setFormData({
            ...formData,
            fromDate: startDate,
        })
    }, [startDate]);

    useEffect(() => {
        setFormData({
            ...formData,
            toDate: endDate,
        })
    }, [endDate]);

    useEffect(() => {
        setFormData({
            ...formData,
            days: selectedDays,
        })
    }, [selectedDays]);

    useEffect(() => {
        setFormData({
            ...formData,
            language: selectedLanguage,
        })
    }, [selectedLanguage]);

    useEffect(() => {
        setFormData({
            ...formData,
            level: selectedNivel,
        })
    }, [selectedNivel]);

    useEffect(() => {
        setFormData({
            ...formData,
            status: selectedstatus,
        })
    }, [selectedstatus]);

    const handlePreparePayload = () => {
        handleApllyFilters()
    }

    const handleResetFilters = () => {
        setStartDate(null);
        setEndDate(null);
        setSeletedNivel([]);
        setSeletedstatus([]);
        setSeletedLanguage([]);
        setSelectedDays([]);
        checkboxGroupRefDays.current?.reset();
        checkboxGroupRefStatus.current?.reset();
        checkboxGroupRefLanguage.current?.reset();
        checkboxGroupRefNivel.current?.reset();
        handleRemoveFilter();
    }


    const filteredCourseIdentifier = findCourseIdentifier === ''
        ? identifiers
        : identifiers.filter((identifier) =>
            identifier.description
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(findCourseIdentifier.toLowerCase().replace(/\s+/g, ''))
        );

    const filteredTeachers = findTeacher === ''
        ? teachers
        : teachers.filter((teacher) =>
            teacher.name
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(findTeacher.toLowerCase().replace(/\s+/g, ''))
        );

    const handleSelectionDaysChange = (selectedItems) => {
        setSelectedDays(selectedItems);
    };

    const handleSelectionLanguegeChange = (selectedItems) => {
        setSeletedLanguage(selectedItems);
    };

    const handleSelectionStatusChange = (selectedItems) => {
        setSeletedstatus(selectedItems);
    };

    const handleSelectionNivelChange = (selectedItems) => {
        setSeletedNivel(selectedItems);
    };

    const handleCancelFilters = () => {
        checkboxGroupRefDays.current?.updateSelection(savedFilterData.days ? savedFilterData.days : []);
        checkboxGroupRefStatus.current?.updateSelection(savedFilterData.status ? savedFilterData.status : []);
        checkboxGroupRefLanguage.current?.updateSelection(savedFilterData.language ? savedFilterData.language : []);
        checkboxGroupRefNivel.current?.updateSelection(savedFilterData.level ? savedFilterData.level : []);
        toggleDrawerFilters();
    };

    return (
        <DrawerCustom
            isOpen={isOpenDrawerFilters}
            toggleDrawer={toggleDrawerFilters}
            direction='left'
            title='Filtros de la lista de cursos'
            subtitle='Selecciona los filtros aplicar para a la lista de los cursos'
            footer={(
                <div className='flex items-center justify-end gap-4 overflow-hidden'>
                    <button
                        type='button'
                        onClick={() => handleResetFilters()}
                        className='rounded-md font-semibold text-md text-gray-500 bg-gray-100 hover:bg-gray-300 px-4 py-2'
                    >{"Limpiar"}</button>
                    <button
                        type='button'
                        onClick={() => handleCancelFilters()}
                        className='rounded-md font-semibold text-md text-gray-600 bg-white hover:bg-gray-200 px-4 py-2'
                    >{"Cancelar"}</button>
                    <button
                        type='button'
                        onClick={() => handlePreparePayload()}
                        className='rounded-md font-semibold text-md text-white bg-indigo-600 hover:bg-indigo-700 px-4 py-2'
                    >{"Aplicar"}</button>
                </div>
            )}
        >
            <div className='w-full flex flex-col'>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <div className='w-full'>
                        <InputDateWithIcon
                            id={'startDay'}
                            onChange={setStartDate}
                            value={startDate}
                            label={'Inicio curso'}
                            placeHolder={'Fecha de inicio'}

                        />
                    </div>
                    <div className='w-full'>
                        <InputDateWithIcon
                            id={'endDay'}
                            onChange={setEndDate}
                            value={endDate}
                            label={'Fin curso'}
                            placeHolder={'Fecha de final'}

                        />
                    </div>
                    <div className='w-full'>
                        <h3 className="text-md mb-4 font-semibold text-gray-900">Identificador</h3>
                        <ComboBox
                            filterData={filteredCourseIdentifier}
                            query={findCourseIdentifier}
                            setQuery={setFindCourseIdenfier}
                            selected={formData}
                            setSelected={setFormData}
                            placeholder='Identificador'
                            property='identifier'
                        />
                    </div>
                    <div className='w-full'>
                        <h3 className="text-md mb-4 font-semibold text-gray-900">Profesor</h3>
                        <div className='w-full'>
                            <ComboBox
                                filterData={filteredTeachers}
                                query={findTeacher}
                                setQuery={setFindTeacher}
                                selected={formData}
                                setSelected={setFormData}
                                placeholder='Profesor'
                                property='teacher'
                            />
                        </div>
                    </div>
                    <div>
                        <InputCheckboxGroup
                            items={listDays}
                            label="Dias de curso"
                            onChange={handleSelectionDaysChange}
                            ref={checkboxGroupRefDays}
                        />
                    </div>
                    <div>
                        <InputCheckboxGroup
                            items={statusCourse}
                            // items={getValuesFromCollectionsItems(statusCourse)}
                            label="Estatus"
                            onChange={handleSelectionStatusChange}
                            ref={checkboxGroupRefStatus}
                        />
                    </div>
                    <div>
                        <InputCheckboxGroup
                            items={languages}
                            // items={getValuesFromCollectionsItems(languages)}
                            label="Idioma"
                            onChange={handleSelectionLanguegeChange}
                            ref={checkboxGroupRefLanguage}
                        />
                    </div>
                    <div>
                        <InputCheckboxGroup
                            items={levels}
                            // items={getValuesFromCollectionsItems(levels)}
                            label="Nivel"
                            onChange={handleSelectionNivelChange}
                            ref={checkboxGroupRefNivel}
                        />
                    </div>
                </div>
            </div>
        </DrawerCustom>
    )
}
