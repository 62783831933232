import { NumericFormat } from "react-number-format";

export const InputCurrency = ({
	name,
	label,
	formData,
	setFormData,
	placeholder, 
	disabled
}) => {
	// 🔹 Función para manejar el cambio
	const handleValueChange = (values) => {
		const { floatValue } = values; // Obtiene el valor numérico sin el formato
		setFormData((prev) => ({ ...prev, [name]: floatValue }));
	};
	return (
		<div>
			<label
				htmlFor={name}
				className="block text-md font-semibold text-gray-900 leading-6 ml-1"
			>
				{label}
			</label>
			<div className="mt-2">
				<NumericFormat
					value={formData[name] || ""}
					thousandSeparator=","
					decimalSeparator="."
					decimalScale={2}
					fixedDecimalScale
					prefix="$ "
					onValueChange={handleValueChange}
					placeholder={placeholder || "$ 0.00"}
					disabled={disabled}
					className="block w-full focus:bg-indigo-50 rounded-md border-0 py-2 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
				/>
			</div>
		</div>
	);
};
