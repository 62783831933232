import React, { useState, forwardRef, useImperativeHandle } from "react";

export const InputCheckboxGroup = forwardRef(({ items = [], label = "", onChange }, ref) => {
    const [selectedItems, setSelectedItems] = useState([]);

    // Manejar selección de checkboxes
    const handleCheckboxChange = (item) => {
        const updatedSelection = selectedItems.includes(item.value)
            ? selectedItems.filter((i) => i !== item.value) // Quitar si ya está seleccionado
            : [...selectedItems, item.value]; // Agregar si no está seleccionado

        setSelectedItems(updatedSelection);

        // Notificar los elementos seleccionados al componente padre
        if (onChange) {
            onChange(updatedSelection);
        }
    };

    // Método para reiniciar la selección
    const resetSelection = () => {
        setSelectedItems([]);
        if (onChange) {
            onChange([]);
        }
    };

    // Método para actualizar la selección desde afuera
    const updateSelection = (newSelection) => {
        setSelectedItems(newSelection);
        if (onChange) {
            onChange(newSelection);
        }
    };

    // Exponer métodos a través de `ref`
    useImperativeHandle(ref, () => ({
        reset: resetSelection,
        updateSelection, // Permite actualizar los checkboxes externamente
    }));

    return (
        <div className="w-full border-gray-200 border-[1px] px-8 py-4 rounded-lg">
            {/* Etiqueta dinámica */}
            {label && <h2 className="block text-md font-semibold text-gray-900 leading-6">{label}</h2>}

            {/* Lista de checkboxes */}
            <div className="flex flex-col flex-1 mt-4 gap-2">
                {items.map((item) => (
                    <div key={item.value} className="flex items-center ml-4">
                        <input
                            id={`${item.value}-checkbox`}
                            type="checkbox"
                            checked={selectedItems.includes(item.value)}
                            onChange={() => handleCheckboxChange(item)}
                            className="h-4 w-4 text-green-500 checked:bg-indigo-600 focus:ring-indigo-500 border-gray-300 rounded-lg"
                        />
                        <label
                            htmlFor={`${item.value}-checkbox`}
                            className="ml-4 text-md text-gray-700 leading-6"
                        >
                            <span className="font-normal text-gray-900 capitalize">{item.description}</span>
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
});
